import Vue from 'vue'
import store from '@/store'
import vuetify from '../plugins/vuetify.js'
import { aesDecrypt } from '@/utils'

const uspAuthAppURL = 'https://usp20-auth.perahub.com.ph'

export default (to, from, next) => {
  if (Vue.$cookies.isKey('USP_DATA') && Vue.$cookies.isKey('USP_TOKEN')) {
    const userData = JSON.parse(aesDecrypt(Vue.$cookies.get('USP_DATA')))
    const userToken = JSON.parse(aesDecrypt(Vue.$cookies.get('USP_TOKEN')))
    // const userModules = JSON.parse(aesDecrypt(Vue.$cookies.get('USP_MODULES')))
    const userAuthTimestamp = JSON.parse(aesDecrypt(Vue.$cookies.get('USP_AUTH_TIMESTAMP')))

    if (userData && userToken) {
      if (userToken) userData.token = userToken
      // if (userModules) userData.modules = userModules

      store.commit('auth/AUTH_SUCCESS', true)
      store.commit('auth/SET_RUN_SESSION', true)
      store.commit('auth/SET_IS_OTP_PASSED', true)
      store.commit('auth/SET_CURRENT_USER', userData)
      store.commit('auth/SET_AUTH_TIMESTAMP', userAuthTimestamp)

      store.dispatch('users/retrieveUserSignature', { user_id: userData.user_id }).then(res => {
        if (res.result.length !== 0) {
          store.state.auth.currUser.userSignature = res.result[0].bucket_path
        }
      })

      store.dispatch('eReceipt/getBranchDetail', { location_id: '03/' + userData.location_code }).then(res => {
        store.state.auth.currUser.branchDetail = res.result
      })
    } else {
      store.dispatch('auth/resetAuthentication')
    }
  } else {
    store.dispatch('auth/resetAuthentication')
    location.href = `${uspAuthAppURL}/login`
  }

  if (to.meta.requiresAuth && !store.state.auth.isAuthenticated) {
    store.commit('auth/SET_IS_OTP_PASSED', false)
    location.href = `${uspAuthAppURL}/login`
  } else if (to.meta.requiresAuth && store.state.auth.isAuthenticated && !store.state.auth.isOTPpassed) {
    store.commit('auth/SET_IS_OTP_PASSED', false)
    location.href = `${uspAuthAppURL}/verify-otp-code`
  } else {
    store.commit('SET_PAGE_TITLE', to.meta.title)

    if (to.meta.mini) {
      if (vuetify.framework.breakpoint.width > 1263) {
        store.commit('app/TOGGLE_MINI_SIDEBAR', true)
      } else {
        store.commit('app/TOGGLE_MINI_SIDEBAR', false)
      }
    } else {
      store.commit('app/TOGGLE_MINI_SIDEBAR', false)
    }

    next()
  }
}
