import { endpoints } from '@/api/endpoints'

const transactionEditApproval = endpoints.use('transactionEditApproval')

export default {
  trxAction: ({ commit }, payload) => new Promise((resolve, reject) => {
    transactionEditApproval.trxAction(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getGrid: ({ commit }, payload) => new Promise((resolve, reject) => {
    transactionEditApproval.getGrid(payload)
      .then(({ data }) => {
        commit('SET_GRID', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
