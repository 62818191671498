import { render, staticRenderFns } from "./TransactionLimitIndex.vue?vue&type=template&id=a0961eb6&scoped=true"
import script from "./TransactionLimitIndex.vue?vue&type=script&lang=js"
export * from "./TransactionLimitIndex.vue?vue&type=script&lang=js"
import style0 from "./TransactionLimitIndex.vue?vue&type=style&index=0&id=a0961eb6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0961eb6",
  null
  
)

export default component.exports