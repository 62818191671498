<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col :cols="12" :sm="4" :md="4">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search Transaction"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-8" :cols="12">
        <v-data-table
          :headers="headers"
          :items="transactions"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:[`item.trx_date`]="{ item }">
            <span v-if="item.trx_date">
              {{ formatDate(item.trx_date) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.form_number`]="{ item }">
            <span v-if="item.form_number && (((item.category === 'COC7' || item.category === 'CTPL') && item.api_status === 1) || ((item.category !== 'COC7' || item.category !== 'CTPL') && (item.adj_status === '0' || item.adj_status === '3'))) && item.amount > 0">
              {{ item.form_number }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.coc_number`]="{ item }">
            <span v-if="item.coc_number && (((item.category === 'COC7' || item.category === 'CTPL') && item.api_status === 1) || ((item.category !== 'COC7' || item.category !== 'CTPL') && (item.adj_status === '0' || item.adj_status === '3'))) && item.amount > 0">
              {{ item.coc_number }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.full_name`]="{ item }">
            <span v-if="item.customer_name">
              <v-avatar class="mr-1" size="26px" :style="avatarColor(item.customer_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.customer_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.customer_name) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.beneficiary`]="{ item }">
            <span v-if="item.beneficiary">
              <v-avatar class="mr-1" size="26px" :style="avatarColor(item.beneficiary)">
                <span class="white--text text-body-2">{{ avatarInitial(item.beneficiary) }}</span>
              </v-avatar>
              {{ toProperCase(item.beneficiary.split('|')[0]) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <span v-if="item.amount">
              {{ toMoney(item.amount) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="(item.adj_status === '0' || item.adj_status === '3' || item.api_status === 1) && item.amount > 0"
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="item.category !== 'COC7' && item.category !== 'CTPL'" @click="openViewDialog(item)">
                  <v-list-item-title>
                    View Details
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="printOAR(item)" v-if="currUser.location_type === '1'">
                  <v-list-item-title>
                    Print OAR
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="reprintCOC(item)" v-if="checkIfUploaded(item)">
                  <v-list-item-title>
                    Print COC
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="retrieveCOCWacom(item, item.category)" v-if="!checkIfUploaded(item)">
                  <v-list-item-title>
                    Print COC
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.category !== 'COC7' && item.category !== 'CTPL'" @click="confirmAdjustment(item)">
                  <v-list-item-title class="red--text">
                    Cancel
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tooltip left v-if="item.adj_status == 0 && (item.category === 'COC3' || item.category === 'COC4')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="requestCancellation(item)" color="primary" icon>
                  <v-icon>mdi-marker-cancel</v-icon>
                </v-btn>
              </template>
              <span>Request for Transaction Cancellation</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="cancelDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Cancel Transaction
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          <p>
            Are you sure you want to cancel this transaction?
          </p>
          <v-form ref="cancelForm">
            <v-text-field
              v-model="requestReason"
              class="ph-textbox"
              label="Reason for Adjustment"
              :rules="requiredRules"
              autofocus
              outlined
            />
          </v-form>
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="closeCancelDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="submitAdjustment"
            class="ph-action-button"
            :disabled="!requestReason"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cancelTrxDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Cancel Selected Transaction
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          <p>
            Are you sure you want to request this transaction for cancellation?
          </p>
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="closeCancelTrxDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="executeRequestCancel"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="viewDialog"
      v-if="selectedTransaction"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Details
        </v-card-title>
        <br>
        <v-card-text>
          <v-row>
            <v-col class="text-right" :cols="4">
              Transaction Date:
            </v-col>
            <v-col :cols="8">
              <strong>{{ formatDate(selectedTransaction.trx_date) }}</strong>
            </v-col>
            <v-col class="text-right" :cols="4">
              Insurance:
            </v-col>
            <v-col :cols="8">
              <strong>{{ getInsuranceName(selectedTransaction.category) }}</strong>
            </v-col>
            <v-col class="text-right" :cols="4">
              Insured Name:
            </v-col>
            <v-col :cols="8">
              <strong>{{ selectedTransaction.customer_name }}</strong>
            </v-col>
            <v-col class="text-right" :cols="4">
              Insured Occupation:
            </v-col>
            <v-col :cols="8">
              <strong>{{ selectedTransaction.occupation }}</strong>
            </v-col>
            <v-col class="text-right" :cols="4">
              Beneficiary Name:
            </v-col>
            <v-col :cols="8">
              <strong>
                {{ selectedTransaction.beneficiary.split('|')[0] }}
                ({{ selectedTransaction.beneficiary.split('|')[1] }})
              </strong>
            </v-col>
            <v-col class="text-right" :cols="4">
              Inception Date:
            </v-col>
            <v-col :cols="8">
              <strong>{{ selectedTransaction.inception_date }}</strong>
            </v-col>
            <v-col class="text-right" :cols="4">
              Expiration Date:
            </v-col>
            <v-col :cols="8">
              <strong>{{ selectedTransaction.expiry_date }}</strong>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="closeViewDialog"
            class="ph-action-button"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-fab-transition>
      <v-btn
        @click="newTransaction"
        color="primary"
        fab
        large
        fixed
        dark
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <wacom-idle v-if="currUser.has_wacom" />
  </v-container>
</template>

<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { format, differenceInYears } from 'date-fns'
import {
  getInsuranceName,
  avatarInitial,
  avatarColor,
  toProperCase,
  renderToast,
  toMoney
} from '@/utils'
import wacomMixin from '../../../src/mixins/wacom.js'
import wacomIdle from '@/components/wacom/wacomIdle'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      policies: 'insurance/policies'
    })
  },
  components: {
    wacomIdle
  },
  mixins: [wacomMixin],
  data () {
    return {
      search: '',
      selectedTransaction: null,
      cancelDialog: false,
      viewDialog: false,
      requestReason: '',
      transactions: [],
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      breadcrumbsItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Protect',
          disabled: true
        }
      ],
      filteredCOCs: [],
      headers: [
        {
          text: 'Date',
          sortable: true,
          value: 'trx_date',
          width: '100px'
        },
        {
          text: 'OAR Number',
          sortable: true,
          value: 'form_number',
          width: '120px'
        },
        {
          text: 'COC Number',
          sortable: true,
          value: 'coc_number',
          width: '120px'
        },
        {
          text: 'Insured',
          sortable: true,
          value: 'full_name',
          width: '150px'
        },
        {
          text: 'Beneficiary',
          sortable: true,
          value: 'beneficiary',
          width: '150px'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'amount',
          width: '140px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '140px'
        }
      ],
      cancelTrxRemarks: '',
      cancelTrxDialog: false,
      cancelRequestPayload: {}
    }
  },
  methods: {
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    async reprintCOC (item) {
      var reprintCOCPayload
      var cocEndpoint
      var cocEndpoint2
      var age = differenceInYears(new Date(), new Date(item.birth_date))
      if (item.category === 'COC') {
        cocEndpoint = 'coc/reprintMalayan'
        reprintCOCPayload = {
          policy_face_value: item.amount,
          location_id: this.currUser.location_id,
          coc_number: item.coc_number,
          customer_id: item.customer_id,
          vuser_fullname: item.customer_name,
          inception_date: item.inception_date,
          has_wacom: this.currUser.has_wacom
          // vuser_fullname: item.customer_name,
          // vexpiredate: item.expiry_date,
          // vref_no: item.coc_number,
          // vinceptiondate: item.inception_date,
          // vbirthdate: item.birth_date,
          // vage: age,
          // voccupation: item.occupation,
          // vrelationship: '',
          // beneficiary: item.beneficiary,
          // fla: this.currUser.first_name + ' ' + this.currUser.last_name
        }
      } else if (item.category === 'COC3') {
        cocEndpoint = 'coc/printDengueProtectPlus'
        reprintCOCPayload = {
          vtrx_date: item.trx_date,
          vuser_fullname: item.customer_name,
          vexpiredate: item.expiry_date,
          vref_no: item.coc_number,
          vinceptiondate: item.inception_date,
          vbirthdate: item.birth_date,
          vage: age,
          voccupation: item.occupation,
          vrelationship: '',
          beneficiary: item.beneficiary
        }
      } else if (item.category === 'COC4') {
        cocEndpoint = 'coc/printPeraPayapa'
        reprintCOCPayload = {
          vtrx_date: item.trx_date,
          vuser_fullname: item.customer_name,
          vexpiredate: item.expiry_date,
          vref_no: item.coc_number,
          vinceptiondate: item.inception_date,
          vbirthdate: item.birth_date,
          vage: age,
          beneficiary: item.beneficiary,
          vinsurance: this.formatAmount(item.plan_amount)
        }
      } else if (item.category === 'COC7' || item.category === 'CTPL') {
        cocEndpoint = 'coc/printCTPL'
        reprintCOCPayload = {
          af_number: item.af_number,
          policy_number: item.policy_number,
          name: item.customer_name,
          address: item.address,
          coc_number: item.coc_number,
          date_issued: item.trx_date,
          inception_date: item.inception_date,
          expiry_date: item.expiry_date,
          model: item.year,
          make: item.make,
          type_of_body: item.type_of_body,
          color: item.color,
          mv_file_number: item.file_number,
          plate_number: item.plate_number,
          chassis_number: item.chassis_number,
          engine_number: item.engine_number,
          premium_paid: item.amount
        }
      } else if (item.category === 'COC8') {
        const splitBeneficiary = item.beneficiary.split('|')
        const beneficiary = splitBeneficiary[0]
        const relationship = splitBeneficiary[1]
        cocEndpoint = 'coc/printDengueProtectv2'
        reprintCOCPayload = {
          policy_face_value: item.amount,
          location_id: this.currUser.location_id,
          coc_number: item.coc_number,
          customer_id: item.customer_id,
          vuser_fullname: item.customer_name,
          inception_date: item.inception_date,
          has_wacom: this.currUser.has_wacom,
          vexpiredate: item.expiry_date,
          vref_no: item.coc_number,
          insurance_type: item.category,
          vinceptiondate: item.inception_date,
          vbirthdate: item.birth_date,
          vage: age,
          vtrx_date: item.trx_date,
          voccupation: item.occupation,
          vrelationship: relationship,
          beneficiary: beneficiary,
          crn: item.customer_id,
          vlocation_name: this.currUser.location_name,
          uploaded_by: this.currUser.first_name
          // fla: this.currUser.first_name + ' ' + this.currUser.last_name
        }
        console.log(reprintCOCPayload)
      } else if (item.category === 'COC6') {
        const getPolicies = await this.$store.dispatch('insurance/getPolicies', { category: 'COC6' })
        var filtered = getPolicies.result.filter(function (e) {
          return e.price === item.amount
        })
        cocEndpoint = 'coc/printCovidProtectPlus'
        reprintCOCPayload = {
          vtrx_date: item.trx_date,
          vuser_fullname: item.customer_name,
          vexpiredate: item.expiry_date,
          vref_no: item.coc_number,
          plan_type: filtered[0].policy_name
        }
      } else if (item.category === 'COC9' || item.category === 'COC10') {
        cocEndpoint2 = 'insurance/getPOC'
        cocEndpoint = 'insurance/getCOC'
        reprintCOCPayload = {
          traceNo: item.trace_number
        }
      }
      if (item.category === 'COC9' || item.category === 'COC10') {
        const reqPOC = await this.$store.dispatch(cocEndpoint2, reprintCOCPayload)
        const reqCOC = await this.$store.dispatch(cocEndpoint, reprintCOCPayload)
        var COCdecoded = Buffer.from(reqCOC.result, 'base64')
        var POCdecoded = Buffer.from(reqPOC.result, 'base64')
        var COCblob = new Blob([COCdecoded], { type: 'application/pdf' })
        var POCblob = new Blob([POCdecoded], { type: 'application/pdf' })
        var COCfileURL = URL.createObjectURL(COCblob)
        var POCfileURL = URL.createObjectURL(POCblob)
        window.open(COCfileURL, '_blank')
        setTimeout(() => {
          window.open(POCfileURL, '_blank')
        }, 1000)
      } else {
        const reqCOC = await this.$store.dispatch(cocEndpoint, reprintCOCPayload)
        var decoded = Buffer.from(reqCOC.result, 'base64')
        var blob = new Blob([decoded], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(blob)
        window.open(fileURL, '_blank')
      }
    },
    async printOAR (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptID',
        {
          location_id: this.currUser.location_id,
          receipt_type: '2',
          or_series_no: '',
          oar_series_no: data.form_number,
          trx_date: this.currUser.trx_date
        }
      )
      this.getOARDetail(res.result)
    },
    async getOARDetail (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptByID', data.ereceipt_api_id)
      this.downloadOAR(res.result)
    },
    async downloadOAR (data) {
      var payload = { application_id: data.application_id, ereceipt_api_id: data.id }
      const res = await this.$store.dispatch('eReceipt/downloadEreceipt', payload)
      var newBlob = new Blob([res], { type: 'application/pdf' })
      var newFileURL = URL.createObjectURL(newBlob)
      window.open(newFileURL, '_blank')
    },
    // printOAR (data) {
    //   if (data.category === 'COC7' || data.category === 'CTPL') {
    //     window.open(`/receipt?ref=${data.form_number}&trx_type=ctpl&type=OAR`, '_blank')
    //   } else {
    //     window.open(`/receipt?ref=${data.form_number}&trx_type=insurance&type=OAR`, '_blank')
    //   }
    // },
    openViewDialog (data) {
      this.selectedTransaction = data
      this.viewDialog = true
    },
    closeViewDialog () {
      this.selectedTransaction = null
      this.viewDialog = false
    },
    closeCancelDialog () {
      this.selectedTransaction = null
      this.cancelDialog = false
      this.requestReason = ''
      this.$refs.cancelForm.resetValidation()
    },
    confirmAdjustment (data) {
      this.selectedTransaction = data
      this.cancelDialog = true
    },
    async submitAdjustment () {
      if (this.selectedTransaction) {
        // const payload = {
        //   adj_status: '1',
        //   adj_requested_by: this.currUser.user_id,
        //   adj_requested_reason: this.requestReason,
        //   adj_category: this.selectedTransaction.category,
        //   id: this.selectedTransaction.trx_insurance_id ? this.selectedTransaction.trx_insurance_id : this.selectedTransaction.id
        // }

        // added new api insertion for transaction edit approval
        let COCname

        switch (this.selectedTransaction.category) {
          case 'COC':
            COCname = 'Malayan'
            break
          case 'COC3':
            COCname = 'Dengue Protect'
            break
          case 'COC4':
            COCname = 'Eternal Plans'
            break
          case 'COC6':
            COCname = 'Covid Protect'
            break
          case 'COC7':
            COCname = 'Confirmation'
            break
          case 'COC8':
            COCname = 'Dengue Protect v2'
            break
          case 'COC9':
            COCname = 'Pera Payapa 2.0'
            break
          case 'COC10':
            COCname = 'Microsafe'
            break
        }
        const payload = {
          module: 'peraprotect',
          record_id: this.selectedTransaction.trx_insurance_id ? this.selectedTransaction.trx_insurance_id : this.selectedTransaction.id,
          user_id: this.selectedTransaction.user_id,
          reason: this.requestReason,
          client_ip: this.currUser.ip_address,
          action: COCname,
          location_id: this.selectedTransaction.location_id,
          requestor_id: this.currUser.user_id
        }

        if (this.$refs.cancelForm.validate()) {
          // const req = await this.$store.dispatch('insurance/requestAdjustment', payload)

          const req = await this.$store.dispatch('requestAdjustment', payload)

          if (req.code === 200) {
            this.getGrid()
            this.closeCancelDialog()
            renderToast('success', 'Successful', 'Pera Protect Adjustment has been requested successfully')
          } else {
            renderToast('error', 'Error', 'Failed to cancel pera protect transaction')
          }
        }
      } else {
        renderToast('error', 'Transaction Selection Error', 'Please hard reload the page then try again')
      }
    },
    newTransaction () {
      this.$store.dispatch('insurance/resetForm')
      this.$router.push({ path: '/pera-protect/new' })
    },
    formatDate (date) {
      if (date) return format(new Date(date), 'yyyy-MM-dd')
      return ''
    },
    async getGrid () {
      const req = await this.$store.dispatch('insurance/getGrid', {
        trxDate: this.currUser.trx_date,
        locationID: this.currUser.location_id
      })

      if (req.code === 200) {
        this.transactions = req.result
      }
    },
    checkUploadedFiles () {
      const self = this
      console.log(self.filteredCOCs, 'filteredCOC')
      console.log(self.transactions)
      var transactionsWithWacomCOCs = []
      self.transactions.forEach(function (e) {
        self.filteredCOCs.forEach(function (f) {
          if (e.control_number === f.ref_number) {
            transactionsWithWacomCOCs.push(e)
          }
        })
      })
      return transactionsWithWacomCOCs
    },
    checkIfUploaded (item) {
      var filtered = this.filteredCOCs.filter(function (e) {
        return e.ref_number === item.coc_number
      })
      if (filtered.length !== 0) {
        return false
      } else {
        return true
      }
    },
    async retrieveCOCWacom (item, type) {
      let filtered = {}
      if (type === 'COC') {
        filtered = this.filteredCOCs.filter(function (e) {
          return e.ref_number === item.coc_number && e.type === 'coc'
        })

        const payload = {
          file: btoa(filtered[0].bucket_path)
        }
        const res = await this.$store.dispatch('acr/retrieveACRFile', payload)
        var extension = res.result.extension
        var file = Buffer.from(res.result.file, 'base64')
        var blob = ''
        if (extension === 'pdf') {
          blob = new Blob([file], { type: 'application/pdf' })
        } else if (extension === 'jpg') {
          blob = new Blob([file], { type: 'image/jpg' })
        } else if (extension === 'png') {
          blob = new Blob([file], { type: 'image/png' })
        } else if (extension === 'jpeg') {
          blob = new Blob([file], { type: 'image/jpeg' })
        }
        var fileURL = URL.createObjectURL(blob)
        window.open(fileURL, '_blank')
      }
    },
    requestCancellation (item) {
      this.cancelRequestPayload = item
      this.cancelTrxDialog = true
    },
    closeCancelTrxDialog () {
      this.cancelRequestPayload = {}
      this.cancelTrxDialog = false
    },
    async executeRequestCancel () {
      const item = this.cancelRequestPayload
      const payload = {
        id: item.id,
        service_code: 'peraprotect',
        status: '1',
        location_id: this.currUser.location_id,
        payload: {
          adj_status: '1',
          adj_requested_by: this.currUser.user_id,
          adj_requested_date: this.currUser.trx_date,
          adj_requested_reason: this.cancelTrxRemarks,
          adj_category: 'cancel'
        }
      }

      await this.$store.dispatch('transactionCancel/cancelRequest', payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          renderToast('success', 'Success', 'The Transaction Cancellation has been successfully requested.')
          this.closeCancelTrxDialog()
        } else {
          renderToast('error', 'Error', res.message)
        }
      })
    },
    getInsuranceName,
    avatarInitial,
    avatarColor,
    toProperCase,
    toMoney
  },
  beforeMount () {
    this.getGrid()
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
  },
  async mounted () {
    const payload = {
      location_id: this.currUser.location_id,
      trx_date: this.currUser.trx_date.replace(' 00:00:00', ''),
      user_id: this.currUser.user_id
    }

    const cocs = await this.$store.dispatch('insurance/getSignedCOCList', payload)
    this.filteredCOCs = cocs.data
    this.checkUploadedFiles()
  }
}
</script>
