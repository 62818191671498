// Store functionality
import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/store/state'
import getters from '@/store/getters'
import mutations from '@/store/mutations'
import actions from '@/store/actions'
import modules from '@/store/modules'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
var ls = new SecureLS({ encodingType: 'aes' })

Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      },
      paths: [
        'auth.isAuthenticated',
        'auth.isOTPpassed',
        'auth.token',
        'auth.currUser',
        'auth.showRefreshPrompt',
        'auth.authTimestamp',
        'auth.runSession',
        'customers.currentView',
        'obo.remoteData',
        'wu.mcRateData',
        'bills.bayadReceiptData',
        'bills.tpaId',
        'bills.billsCollectionVoucherData',
        'bills.billsLoanAccountNo',
        'loans.voucherDetails',
        'dali.loanAccountNo',
        'nonex.isGCashTrx',
        'loans.peraLoanTransactions',
        'dali.peraDaliTransactions',
        'send.fieldsList',
        'send.combosList',
        'wuPayments.orderCurrentTrx',
        'compliance.module'
      ]
    })
  ]
})

export default store
