import apigee from '@/api/apigee'

export default {
  trxAction (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/approve-trx-edit-adjustment', payload)
  },
  getGrid (userId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/trx-edit-list?user_id=${userId}`)
  }
}
