export default {
  pageTitle: '',
  receipt: {},
  oar: {
    location_form_id: '',
    series_no: '',
    isUsed: false
  },
  collections: {
    sourceOfFund: [],
    purposeOfTransaction: [],
    occupation: [],
    relationship: [],
    idList: [],
    wuIDList: [],
    countries: [],
    natureOfWork: [],
    positionLevel: [],
    employment: [],
    cities: [],
    d2bcountries: [],
    stateAndCities: [],
    locations: [],
    wudasCountries: [],
    d2bCorridors: [],
    userTypes: [
      {
        id: 1,
        name: 'Teller'
      },
      {
        id: 2,
        name: 'Admin'
      },
      {
        id: 3,
        name: 'Accounting'
      },
      {
        id: 4,
        name: 'Compliance'
      },
      {
        id: 5,
        name: 'BOS'
      },
      {
        id: 6,
        name: 'RH'
      }
    ],
    qpCompanies: [],
    qpStateCities: [],
    currencies: [
      {
        text: 'PESO',
        value: 1
      },
      {
        text: 'US DOLLAR',
        value: 2
      }
    ],
    deliveryServiceTypes: [
      {
        text: 'MONEY IN MINUTES',
        value: 'MIM'
      },
      {
        text: 'DIRECT TO BANK',
        value: 'D2B'
      },
      {
        text: 'MOBILE MONEY TRANSFER',
        value: 'MMT'
      }
    ],
    gender: [
      {
        text: 'Male',
        value: 'male'
      },
      {
        text: 'Female',
        value: 'female'
      }
    ],
    civilStatus: [
      {
        text: 'Single',
        value: 'Single'
      },
      {
        text: 'Married',
        value: 'Married'
      },
      {
        text: 'Widow/Er',
        value: 'Widow/Er'
      },
      {
        text: 'Separated',
        value: 'Separated'
      },
      {
        text: 'Divorced',
        value: 'Divorced'
      }
    ],
    routeTypes: [
      { text: 'One-Way', value: 'OW' },
      { text: 'Round Trip', value: 'RT' }
    ],
    flightTypes: [
      { text: 'Domestic', value: 'DOM' },
      { text: 'International', value: 'INT' }
    ],
    ticketStatus: [
      { text: 'CONFIRMED', value: 'CONFIRMED' },
      { text: 'ADD ON', value: 'ADD ON' },
      { text: 'REBOOKED', value: 'REBOOKED' },
      { text: 'FOR VOID', value: 'FOR VOID' },
      { text: 'RESERVED', value: 'RESERVED' },
      { text: 'CONFIRMED REBOOKED', value: 'CONFIRMED REBOOKED' },
      { text: 'FOR REFUND', value: 'FOR REFUND' },
      { text: 'VOIDED', value: 'VOIDED' },
      { text: 'TO REBOOKED', value: 'TO REBOOKED' },
      { text: 'FOR REBOOK', value: 'FOR REBOOK' }
    ],
    remcos: [],
    channels: [
      {
        id: 1,
        channel_name: 'COW',
        channel_code: 'COW'
      },
      {
        id: 2,
        channel_name: 'PRP',
        channel_code: 'PRP'
      },
      {
        id: 3,
        channel_name: 'MAPP',
        channel_code: 'MAPP'
      },
      {
        id: 4,
        channel_name: 'DRP',
        channel_code: 'DRP'
      }
    ],
    providers: []
  },
  prpPolicies: []
}
