const SET_BATCH_UPLOAD_DEFAULT = (state) => {
  state.batchUpload.file = null
  state.batchUpload.fileName = ''
}
const SET_CUSTOMER_LIST = (state, customerList) => {
  state.customerList = customerList
}
const SET_SUCCESSFUL_CUSTOMER_LIST = (state, successfulCustomerList) => {
  state.successfulCustomerList = successfulCustomerList
}
const SET_PENDING_CUSTOMER_LIST = (state, pendingCustomerList) => {
  state.pendingCustomerList = pendingCustomerList
}
const SET_REPROCESS_CUSTOMER_LIST = (state, reProcessCustomerList) => {
  state.reProcessCustomerList = reProcessCustomerList
}
const SET_CUSTOMER_LIST_DEFAULT = (state) => {
  state.customerList = []
}
const SET_SUCCESSFUL_CUSTOMER_LIST_DEFAULT = (state) => {
  state.customerList = []
}
const SET_PENDING_CUSTOMER_LIST_DEFAULT = (state) => {
  state.customerList = []
}
const SET_REPROCESS_CUSTOMER_LIST_DEFAULT = (state) => {
  state.customerList = []
}

export default {
  SET_BATCH_UPLOAD_DEFAULT,
  SET_CUSTOMER_LIST_DEFAULT,
  SET_CUSTOMER_LIST,
  SET_SUCCESSFUL_CUSTOMER_LIST_DEFAULT,
  SET_PENDING_CUSTOMER_LIST_DEFAULT,
  SET_REPROCESS_CUSTOMER_LIST_DEFAULT,
  SET_SUCCESSFUL_CUSTOMER_LIST,
  SET_PENDING_CUSTOMER_LIST,
  SET_REPROCESS_CUSTOMER_LIST
}
