const batchUpload = state => state.batchUpload
const customerList = state => state.customerList
const successfulCustomerList = state => state.successfulCustomerList
const pendingCustomerList = state => state.pendingCustomerList
const reProcessCustomerList = state => state.reProcessCustomerList

export default {
  batchUpload,
  customerList,
  successfulCustomerList,
  pendingCustomerList,
  reProcessCustomerList
}
