<template>
  <v-container fluid>
    <v-row class="mt-16 mb-4 pt-10 mx-2 mt-sm-4 pt-sm-0 mx-sm-2 mx-md-4" no-gutters>
      <v-col :cols="12" :md="4" class="pt-sm-2">
        <h2 class="pt-6 primary--text">
              Successful Cash-In
        </h2>
      </v-col>
    </v-row>
    <v-row class="mt-md-12 mb-md-10" no-gutters>
      <v-col :cols="12">
        <v-data-table
          :headers="headers"
          class="custom-table"
          :calculate-widths="true"
          :items="successfulCustomerList"
          :items-per-page="5"
        >
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="mt-16 mb-4 pt-10 mx-2 mt-sm-4 pt-sm-0 mx-sm-2 mx-md-4" no-gutters>
      <v-col :cols="12" :md="11" class="d-flex align-center">
        <h2 class="primary--text">
          Pending Cash-In
        </h2>
      </v-col>
      <v-col :cols="12" :md="1" class="d-flex justify-end align-center">
        <v-btn
          class="rounded-lg md-4 font-weight-black"
          color="primary"
          block
          x-medium
          @click="retryToCashIn"
        >
          Retry Cash-In
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-md-12 mb-md-10" no-gutters>
      <v-col :cols="12">
        <v-data-table
          :headers="pending"
          class="custom-table"
          :calculate-widths="true"
          :items="pendingCustomerList"
          :items-per-page="5"
          item-key='idtrx_ubp'
        >
        <template v-slot:item.action="{ item, index }">
              <v-checkbox
                v-model="selectedRowsRetry"
                :value="item"
                :key="index"
                :calculate-widths="true"
                hide-details
                color="primary"
                class="mb-4"
              />
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="mt-16 mb-4 pt-10 mx-2 mt-sm-4 pt-sm-0 mx-sm-2 mx-md-4" no-gutters>
      <v-col :cols="12" :md="11" class="d-flex align-center">
        <h2 class="primary--text">
          For Reprocess
        </h2>
      </v-col>
      <v-col :cols="12" :md="1" class="d-flex justify-end align-center">
        <v-btn
          @click="reprocessToCashIn"
          class="rounded-lg md-4 font-weight-black"
          color="primary"
          block
          x-medium
        >
          Reprocess Cash-In
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-md-12 mb-md-10" no-gutters>
      <v-col :cols="12">
        <v-data-table
          :headers="reprocess"
          class="custom-table"
          :calculate-widths="true"
          :items="reProcessCustomerList"
          :items-per-page="5"
          item-key='idtrx_ubp'
        >
        <template v-slot:item.action="{ item, index }">
              <v-checkbox
                v-model="selectedRowsReprocess"
                :value="item"
                :key="index"
                :calculate-widths="true"
                hide-details
                color="primary"
                class="mb-4"
              />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
          <!-- OTP -->
          <v-dialog
      v-model="cashInOTPDialog"
      persistent
      :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '30%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="pb-0">
          <v-spacer />
          <v-btn @click="cashInOTPDialog = false" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-md-4 text-center">
          <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
          <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
            OTP
          </h3>
          <p>
            Input your 6-digit One-time password PIN from the authentication app.
          </p>
          <br>
          <v-otp-input
            v-model="otp"
            id="otp"
            type="number"
            :disabled="isOTPLoading"
            @finish="onFinishOTP"
          />
          <p class="red--text">
            {{ otpMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import { renderToast } from '@/utils'

export default {

  data () {
    return {
      cashInOTPDialog: false,
      isOTPLoading: false,
      otpInvalid: false,
      otp: '',
      otpMessage: '',
      addUserDialog: false,
      updateUserDialog: false,
      deleteUserDialog: false,
      selectedRowsRetry: [],
      selectedForRetry: [],
      selectedRowsReprocess: [],
      selectedForReprocess: [],
      headers: [
        {
          text: 'UB Reference Number',
          sortable: false,
          value: 'ub_reference_no'
        },
        {
          text: 'UB Transaction Date',
          sortable: false,
          value: 'ub_trx_date_time'
        },
        {
          text: 'Reference Number',
          sortable: true,
          value: 'reference_no'
        },
        {
          text: 'Transaction Date',
          sortable: false,
          value: 'trx_date'
        },
        {
          text: 'Account Number',
          sortable: false,
          value: 'card_no'
        },
        {
          text: 'Customer Name',
          sortable: false,
          value: 'customer_name'
        },
        {
          text: 'UB Amount',
          sortable: true,
          value: 'ub_amount'
        },
        {
          text: 'Total Amount',
          sortable: false,
          value: 'total_amount'
        }
      ],
      pending: [
        {
          text: 'UB Reference Number',
          sortable: false,
          value: 'ub_reference_no'
        },
        {
          text: 'UB Transaction Date',
          sortable: false,
          value: 'ub_trx_date_time'
        },
        {
          text: 'Reference Number',
          sortable: true,
          value: 'reference_no'
        },
        {
          text: 'Transaction Date',
          sortable: false,
          value: 'trx_date'
        },
        {
          text: 'Account Number',
          sortable: false,
          value: 'card_no'
        },
        {
          text: 'Customer Name',
          sortable: false,
          value: 'customer_name'
        },
        {
          text: 'UB Amount',
          sortable: true,
          value: 'ub_amount'
        },
        {
          text: 'Total Amount',
          sortable: false,
          value: 'total_amount'
        },
        {
          text: 'Mark for Cash-In',
          sortable: false,
          value: 'action'
        }
      ],
      reprocess: [
        {
          text: 'UB Reference Number',
          sortable: false,
          value: 'ub_reference_no'
        },
        {
          text: 'UB Transaction Date',
          sortable: false,
          value: 'ub_trx_date_time'
        },
        {
          text: 'Reference Number',
          sortable: true,
          value: 'reference_no'
        },
        {
          text: 'Transaction Date',
          sortable: false,
          value: 'trx_date'
        },
        {
          text: 'Account Number',
          sortable: false,
          value: 'card_no'
        },
        {
          text: 'Customer Name',
          sortable: false,
          value: 'customer_name'
        },
        {
          text: 'UB Amount',
          sortable: true,
          value: 'ub_amount'
        },
        {
          text: 'Total Amount',
          sortable: false,
          value: 'total_amount'
        },
        {
          text: 'Expiration Date',
          sortable: false,
          value: 'date_expiry'
        },
        {
          text: 'Days Before Expiration',
          sortable: false,
          value: 'days_before_expiry'
        },
        {
          text: 'Mark for Cash-In',
          sortable: false,
          value: 'action'
        }
      ]
    }
  },
  watch: {
    selectedRowsRetry (selectedData) {
      selectedData.forEach(item => {
        item.cashback_earned = item.total_amount
      })
      this.selectedForRetry = selectedData
    },
    selectedRowsReprocess (selectedData) {
      selectedData.forEach(item => {
        item.cashback_earned = item.total_amount
      })
      this.selectedForReprocess = selectedData
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      successfulCustomerList: 'cashInBatchDisbursment/successfulCustomerList',
      pendingCustomerList: 'cashInBatchDisbursment/pendingCustomerList',
      reProcessCustomerList: 'cashInBatchDisbursment/reProcessCustomerList'
    })
  },
  async mounted () {
    this.refreshGrids()
  },
  methods: {
    renderToast,
    // Open the OTP
    openVerifyOTP () {
      this.otp = ''
      this.cashInOTPDialog = true
    },
    // Checking of OTP
    onFinishOTP (pin) {
      this.otpMessage = ''
      this.isOTPLoading = true
      setTimeout(() => {
        this.doVerify(pin)
      }, 3500)
    },
    // Get the list for Successful table
    async getSuccessfulTransactions () {
      this.$store.commit('app/TOGGLE_LOADING', true)
      await this.$store.dispatch('cashInBatchDisbursment/successfulCashInBatchDisbGrid', {
        trx_date: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
        location_id: this.currUser.location_id
      }).then(res => {
        if (res.data.code === 200) {
          this.$store.commit('app/TOGGLE_LOADING', false)
          this.$store.commit('cashInBatchDisbursment/SET_SUCCESSFUL_CUSTOMER_LIST', res.data.result)
          this.resetData()
        } else {
          this.renderToast('error', 'Error', res.error.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // Get the list for Pending table
    async getPendingTransactions () {
      this.$store.commit('app/TOGGLE_LOADING', true)
      await this.$store.dispatch('cashInBatchDisbursment/pendingCashInBatchDisbGrid', {
        trx_date: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
        location_id: this.currUser.location_id
      }).then(res => {
        if (res.data.code === 200) {
          this.$store.commit('app/TOGGLE_LOADING', false)
          this.$store.commit('cashInBatchDisbursment/SET_PENDING_CUSTOMER_LIST', res.data.result)
          this.resetData()
        } else {
          this.renderToast('error', 'Error', res.error.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // Get the list for reprocess table
    async getReProcessTransactions () {
      this.$store.commit('app/TOGGLE_LOADING', true)
      await this.$store.dispatch('cashInBatchDisbursment/reProcessCashInBatchDisbGrid', {
        trx_date: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
        location_id: this.currUser.location_id
      }).then(res => {
        if (res.data.code === 200) {
          this.$store.commit('app/TOGGLE_LOADING', false)
          this.$store.commit('cashInBatchDisbursment/SET_REPROCESS_CUSTOMER_LIST', res.data.result)
          this.resetData()
        } else {
          this.renderToast('error', 'Error', res.error.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // Refresh the tables
    refreshGrids () {
      this.getSuccessfulTransactions()
      this.getPendingTransactions()
      this.getReProcessTransactions()
    },
    // Reset Data
    resetData () {
      this.selectedForRetry = []
      this.selectedRowsRetry = []
      this.selectedForReprocess = []
      this.selectedRowsReprocess = []
    },
    // Checks if a transaction is selected in pending table
    async retryToCashIn () {
      if (this.selectedForRetry.length !== 0) {
        this.openVerifyOTP()
      } else {
        this.renderToast('error', 'No Transaction(s) selected for Cash In', 'Please select the transaction(s) to Retry')
      }
    },
    // Checks if a transaction is selected in reprocess table
    async reprocessToCashIn () {
      if (this.selectedForReprocess.length !== 0) {
        this.openVerifyOTP()
      } else {
        this.renderToast('error', 'No Transaction/s selected for Cash In', 'Please select the transaction(s) to Reprocess')
      }
    },
    // Verifying the pin
    async doVerify (pin) {
      this.$store.commit('app/TOGGLE_LOADING', true)
      this.otp = pin
      if (this.otp && this.otp.length === 6) {
        if (this.selectedForRetry.length !== 0) {
          this.retryCashInDisb(pin)
        } else if (this.selectedForReprocess.length !== 0) {
          this.reprocessCashInDisb(pin)
        }
      }
    },
    // Retry of the selected transaction
    async retryCashInDisb (pin) {
      const payload = {
        user_id: this.currUser.user_id,
        otp: pin,
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id,
        user_code: this.currUser.user_name,
        customer_data: this.selectedForRetry
      }
      await this.$store.dispatch('cashInBatchDisbursment/retryCashIn', payload).then(res => {
        if (res.data.code === 200) {
          this.$store.commit('app/TOGGLE_LOADING', false)
          this.cashInOTPDialog = false
          this.isOTPLoading = false
          this.renderToast('success', 'Successful', 'The Selected Transactions are Cashed In Successfully.')
          this.refreshGrids()
        } else {
          this.cashInOTPDialog = false
          this.isOTPLoading = false
          this.renderToast('error', res.data.message, res.data.result)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // Reprocess of the selected transaction
    async reprocessCashInDisb (pin) {
      const payload = {
        user_id: this.currUser.user_id,
        otp: pin,
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id,
        user_code: this.currUser.user_name,
        customer_data: this.selectedForReprocess
      }
      await this.$store.dispatch('cashInBatchDisbursment/reProcessCashIn', payload).then(res => {
        if (res.data.code === 200) {
          this.$store.commit('app/TOGGLE_LOADING', false)
          this.cashInOTPDialog = false
          this.isOTPLoading = false
          this.renderToast('success', 'Successful Reprocess', 'The Selected Transactions are Reprocessed Successfully.')
          this.refreshGrids()
        } else {
          this.cashInOTPDialog = false
          this.isOTPLoading = false
          this.renderToast('error', res.data.message, res.data.result)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
