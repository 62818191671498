export default {
  batchUpload: {
    file: null,
    fileName: ''
  },
  customerList: [],
  successfulCustomerList: [],
  pendingCustomerList: [],
  reProcessCustomerList: []
}
