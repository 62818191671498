var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ph-container"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{attrs:{"items":_vm.items}}),_c('v-tabs',{staticClass:"mb-4",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{class:{
          'primary--text font-weight-black': _vm.tab === 0,
          'grey--text': _vm.tab !== 0
        }},[_vm._v(" Batch Upload ")]),_c('v-tab',{class:{
          'primary--text font-weight-black': _vm.tab === 1,
          'grey--text': _vm.tab !== 1
        }},[_vm._v(" Status ")]),_c('v-tab-item',[_c('CashInDisbBatchUpload',{attrs:{"reloadStatus":_vm.reloadStatus}})],1),_c('v-tab-item',[_c('CashInDisbStatus',{ref:"cashInStatus"})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }