<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ma-3">
        <v-col cols="12">
           <v-btn
            color="danger"
            outlined
            text
            light
            class="ph-primary-button ph-stepper-button ph-action-button"
            :disabled="!enableSingleOAR"
            @click="confirmAssign('single')"
            v-if="currUser.location_type === '1'"
          >
            Assign Single OAR
          </v-btn>
          <v-btn
            color="danger"
            outlined
            text
            light
            class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
            @click="confirmAssign('multiple')"
            v-if="currUser.location_type === '1'"
          >
            Assign All OAR
          </v-btn>
          <v-btn
            color="danger"
            outlined
            text
            light
            class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
            @click="retrieveCIS"
            v-if="currUser.location_type === '1'"
          >
            Get CIS Logs
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-card-title class="ph-nonex-datatable-title">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="ph-textbox ph-textbox-grid-search"
                outlined
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="peraPayManualList"
              :search="search"
              :calculate-widths="true"
              class="ph-nonex-datatable"
            >
              <template v-slot:[`item.assign_oar`]="{ item }">
                <v-checkbox
                  v-if="(item.form_number === '' || item.form_number === null) && currUser.location_type === '1'"
                  on-icon="mdi-check-circle"
                  off-icon="mdi-checkbox-blank-circle-outline"
                  v-model="item.assign_oar"
                  @change="selectedTransaction(item)"
                  :disabled="(item.form_number !== '' && item.form_number !== null) || (isSingleOARAssigned === true && item.assign_oar !== true)"
                ></v-checkbox>
                <v-icon class="ph-datatable-checked" color="blue" v-if="item.form_number !== '' && item.form_number !== null">mdi-checkbox-marked-circle-outline</v-icon>
              </template>
              <template v-slot:[`item.trx_date`]="{ item }">
                {{ formatDate(item.trx_date) }}
              </template>
              <template v-slot:[`item.identifier`]="{item}">
                <div class="ph-nonex-receiver-container">
                  <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item.identifier)">
                    <span class="white--text text-body-2">{{ avatarInitial(item.identifier) }}</span>
                  </v-avatar>
                  <p class="ph-nonex-table-names-text">{{item.identifier}}</p>
                </div>
              </template>
              <template v-slot:[`item.amount`]="{item}">
                <p class="ph-nonex-table-names-text">PHP {{formatAmount(item.amount)}}</p>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container green--text" v-if="(item.form_number === '' || item.form_number === null) && currUser.location_type === '1'">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  OAR for Allocation
                </span>
                <span class="ph-nonex-status-container blue--text" v-if="(item.form_number !== '' && item.form_number !== null) && currUser.location_type === '1'">
                  <v-icon color="blue" class="mr-1" x-small>mdi-circle</v-icon>
                  OAR allocated
                </span>
              </template>
              <template v-slot:[`item.action`]="{item}">
                <v-tooltip right v-if="item.form_number === '' || item.form_number === null">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="confirmDelete(item)" color="primary" icon v-if="item.form_number === '' || item.form_number === null">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Entry</span>
                </v-tooltip>
                <v-menu
                  bottom
                  left
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="reprintReceipt(item)">
                      <v-list-item-title>Print Receipt</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="printOAR(item)"  v-if="item.form_number !== '' && item.status === 1 && currUser.location_type === '1'">
                      <v-list-item-title>Print OAR</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-fab-transition>
        <v-btn
          color="primary"
          fab
          large
          fixed
          dark
          bottom
          right
          to="/pera-pay/new"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-container>
    <v-dialog
      v-model="confirmOARAssign"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Assign {{oarAssignType === 'Multiple' ? 'OAR to all transactions?' : `an OAR to ${selectedTrx.TransactionNo}?`}}
        </v-card-title>

        <v-card-text>
          <br>
          <p><b>Are you sure you want to {{oarAssignType === 'Multiple' ? 'assign all items into single OAR?' : 'assign an OAR to your selected transaction?' }}</b></p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            class="ph-action-button ph-stepper-button"
            elevation="0"
            @click="confirmOARAssign = false; oarAssignType = ''"
          >
            No
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="checkEmail(oarAssignType)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Successful
        </v-card-title>

        <v-card-text>
          <br>
          <p><b>The print receipt window will open in 3 seconds.</b></p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="printReceipt"
          >
            Print Receipt
            &nbsp;
            <v-progress-circular
              :rotate="360"
              :value="progressValue"
              color="white"
            >
              {{ countdown }}
            </v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Receipt Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Receipt will send to your email address. Would you like to proceed?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="printReceiptInstead"
            color="primary"
            text
          >
            I want a printed Receipt
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="manualConfirmPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Would you like to proceed with this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="manualConfirmPrompt = false"
            color="primary"
            text
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Delete Manual Entry
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Are you sure you want to delete this entry?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="deleteDialog = false"
            color="primary"
            text
          >
            No
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="deleteData()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  name: 'PeraPayIndex',
  data () {
    return {
      base64String: '',
      billsPaymentList: [],
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Bills',
          disabled: false,
          href: '/pera-bills'
        },
        {
          text: 'Pera Pay',
          disabled: true,
          href: '#'
        }
      ],
      filteredACRs: [],
      search: '',
      headers: [
        {
          text: '',
          sortable: false,
          value: 'assign_oar'
        },
        {
          text: 'Biller',
          sortable: true,
          value: 'biller_name'
        },
        {
          text: 'Account Number',
          sortable: true,
          value: 'account_number'
        },
        {
          text: 'Cash Payment',
          sortable: true,
          value: 'ModeOfPayment'
        },
        {
          text: 'Partner Reference #',
          sortable: true,
          value: 'PartnerRefNo'
        },
        {
          text: 'Receipt Validation #',
          sortable: true,
          value: 'ReceiptValidationNo'
        },
        {
          text: 'Provider',
          sortable: true,
          value: 'provider'
        },
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'trx_date'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'AmountPaid'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      successDialog: false,
      interval: {},
      progressValue: 0,
      countdown: 3,
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      transition: 'slide-y-reverse-transition',
      enableSingleOAR: false,
      enableMultipleOAR: true,
      confirmOARAssign: false,
      oarAssignType: '',
      isSingleOARAssigned: false,
      selectedTrx: {},
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      sendViaEmail: true,
      deletePayload: {},
      deleteDialog: false,
      manualConfirmPrompt: false
    }
  },
  computed: {
    ...mapGetters({
      peraPayManualList: 'peraPay/peraPayManualList',
      collections: 'collections',
      currUser: 'auth/currUser',
      remcos: 'nonex/remcos',
      oarNumber: 'oar/oarNumber',
      receipt: 'receipt',
      seriesDetail: 'manualReceipt/seriesDetail'
    })
  },
  watch: {
    countdown: {
      handler (count) {
        if (count === 0) {
          const that = this
          setTimeout(function () {
            that.progressValue = 0
            that.successDialog = false
          }, 1000)

          clearInterval(this.interval)
        }
      }
    },
    peraPayManualList: {
      handler (newVal) {
        const transactions = newVal
        const filterOARAssigned = transactions.filter(item => { return item.assign_oar === true })

        console.log(filterOARAssigned)

        if (filterOARAssigned.length > 0) {
          this.isSingleOARAssigned = true
          this.enableSingleOAR = true
        } else {
          this.isSingleOARAssigned = false
          this.enableSingleOAR = false
        }
      },
      deep: true
    }
  },
  async beforeMount () {
    this.getTransactions()
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    openOTPDialog () {
      this.withoutEmailPrompt = false
      this.withEmailPrompt = false
      this.manualConfirmPrompt = false
      this.executeAssignOAR()
    },
    proceedTransaction () {
      this.openOTPDialog()
    },
    checkEmail () {
      if (this.currUser.receipt_type === 3) {
        this.sendViaEmail = true
        if (this.currUser.email_address) {
          this.confirmOARAssign = false
          this.withoutEmailPrompt = false
          this.withEmailPrompt = true
        } else {
          this.sendViaEmail = false
          this.executeAssignOAR()
        }
      } else {
        this.withoutEmailPrompt = false
        this.withEmailPrompt = false
        this.sendViaEmail = false
        this.manualConfirmPrompt = true
      }
    },
    printReceiptInstead () {
      this.sendViaEmail = false
      this.openOTPDialog()
    },
    getTransactions () {
      this.$store.dispatch('peraPay/getPeraPayManualGrid', {
        trx_date: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
        location_id: this.currUser.location_id
      })
    },
    formatDateTime (date) {
      return format(parseISO(date), 'yyyy-MM-dd HH:mm:SS')
    },
    formatDate (date) {
      return format(parseISO(date), 'yyyy-MM-dd')
    },
    formatDateTimeACR (date) {
      return format(date, 'yyyy-MM-dd HH:mm:SS')
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    printReceipt (ACRPayload) {
      const that = this
      this.$store.dispatch('customers/generateACR', ACRPayload).then((res) => {
        that.base64String = res.result
        that.pdfConvert()
        that.getTransactions()
      }).catch((error) => {
        console.log(error)
      })
    },
    selectedTransaction (item) {
      if (item.assign_oar) {
        this.selectedTrx = item
      } else {
        this.selectedTrx = {}
      }
    },
    confirmAssign (type) {
      let checkIfNoFormNumber = []

      switch (type) {
        case 'single':
          this.confirmOARAssign = true
          this.oarAssignType = 'Single'
          break
        case 'multiple':
          checkIfNoFormNumber = this.peraPayManualList.filter(item => { return (item.form_number === '' || item.form_number === null) })

          if (checkIfNoFormNumber.length > 0) {
            this.confirmOARAssign = true
            this.oarAssignType = 'Multiple'
          } else {
            this.renderToast('error', 'Multiple OAR Assignment failed', 'Sorry, there are no available transactions to allocate OAR.')
          }

          break
      }
    },
    async executeAssignOAR () {
      this.confirmOARAssign = false

      if (this.currUser.receipt_type === 3) {
        const getReceipt = await this.$store.dispatch('getReceipt', {
          location_id: this.currUser.location_id,
          type: '2',
          application_id: this.currUser.application_id
        })

        if (getReceipt.code === 200) {
          if (this.oarAssignType === 'Single') {
            this.assignSingleOAR()
          } else if (this.oarAssignType === 'Multiple') {
            this.assignMultipleOAR()
          }
        } else {
          renderToast('error', 'Receipt Error', 'Failed to get receipt series data')
        }
      } else {
        const payload = {
          location_id: this.currUser.location_id,
          type: 'OAR'
        }

        await this.$store.dispatch('manualReceipt/getAvailableSeriesNo', payload).then(res => {
          if (res.code >= 200 && res.code <= 299) {
            if (res.result.length > 0) {
              if (this.oarAssignType === 'Single') {
                this.assignSingleOAR()
              } else if (this.oarAssignType === 'Multiple') {
                this.assignMultipleOAR()
              }
            } else {
              this.renderToast('error', 'Error', 'No available series number for this branch. Please request from support.')
            }
          } else {
            this.renderToast('error', 'Error', res.message)
          }
        })
      }
    },
    async assignSingleOAR () {
      console.log(this.selectedTrx)
      const payload = {
        id: this.selectedTrx.trx_bills_payment_temp_id,
        data: {
          location_id: this.selectedTrx.location_id,
          form_number: this.currUser.receipt_type === 3 ? this.receipt.oar_series_no : this.seriesDetail.series_no
        }
      }

      this.$store.dispatch('peraPay/assignSingleManualEntryOAR', payload).then(async res => {
        if (res.message === 'Good') {
          if (this.currUser.receipt_type === 3) {
            const saveReceipt = await this.$store.dispatch('saveReceipt', {
              ereceipt_id: this.receipt.ereceipt_id,
              application_id: this.currUser.application_id,
              application_header_name: this.currUser.branchDetail.header_name,
              application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
              receipt_type: this.receipt.type,
              customer_id: this.currUser.user_id,
              customer_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
              customer_tin_no: '',
              customer_address: '',
              customer_email_address: (this.sendViaEmail) ? this.currUser.email_address : null,
              cashier_id: this.currUser.user_id,
              cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
              oar_receipt_no: this.receipt.oar_receipt_no,
              oar_number: this.receipt.oar_series_no,
              oar_service: 'Pera Bills',
              oar_currency: 'PHP',
              oar_principal_amount: this.selectedTrx.AmountPaid,
              oar_discount: 0,
              oar_total: this.selectedTrx.AmountPaid,
              grand_total: this.selectedTrx.AmountPaid,
              print_email_hold: 0,
              is_forex_logo: 0
            })

            if (saveReceipt.code === 200) {
              if (saveReceipt.result.receipt_status === 1) {
                renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
              } else if (saveReceipt.result.receipt_status === 2) {
                const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                  application_id: this.currUser.application_id,
                  ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                })
                var newBlob = new Blob([res], { type: 'application/pdf' })
                var newFileURL = URL.createObjectURL(newBlob)
                window.open(newFileURL, '_blank')
              } else {
                renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
              }
            } else {
              renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
            }
            this.renderToast('success', 'Single OAR Assignment Successful', 'Single OAR has been assigned successfully.')
          } else {
            const payload = {
              id: this.seriesDetail.id,
              status: 2,
              customer_id: this.currUser.user_id,
              encoded_by: this.currUser.user_id,
              date_encoded: format(new Date(), 'yyyy-MM-dd'),
              amount: this.selectedTrx.AmountPaid,
              description: 'Pera Bills',
              remarks: 'Pera Bills'
            }

            // Generates Manual Receipt with no print. Transaction is automatically saved on reports.
            await this.$store.dispatch('manualReceipt/updateSeriesNo', payload).then(res => {
              if (res.code >= 200 && res.code <= 299) {
                this.renderToast('success', 'Transaction Saved on Record', `Successfully Saved Transaction on Record - OAR Form Number: ${this.seriesDetail.series_no}`)
              } else {
                this.renderToast('error', 'Error', res.message)
              }
            }).catch(err => {
              this.renderToast('error', 'Error', err)
            })
          }

          this.getTransactions()
        } else {
          this.renderToast('error', 'Single OAR Assignment failed', 'There was a failure in assigning single OAR, please try again.')
        }
      })
    },
    async assignMultipleOAR () {
      let overallAmount = 0
      let overallTotal = 0

      this.peraPayManualList.forEach(data => {
        if (data.form_number === '' || data.form_number === null) {
          overallAmount += parseFloat(data.AmountPaid)
          overallTotal += parseFloat(data.AmountPaid)
        }
      })

      const payload = {
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id,
        form_number: this.currUser.receipt_type === 3 ? this.receipt.oar_series_no : this.seriesDetail.series_no,
        customer_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
        AmountDue: overallTotal
      }

      this.$store.dispatch('peraPay/assignMultipleManualEntryOAR', payload).then(async res => {
        if (res.message === 'Good') {
          if (this.currUser.receipt_type === 3) {
            const saveReceipt = await this.$store.dispatch('saveReceipt', {
              ereceipt_id: this.receipt.ereceipt_id,
              application_id: this.currUser.application_id,
              application_header_name: this.currUser.branchDetail.header_name,
              application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
              receipt_type: this.receipt.type,
              customer_id: this.currUser.user_id,
              customer_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
              customer_tin_no: '',
              customer_address: '',
              customer_email_address: (this.sendViaEmail) ? this.currUser.email_address : null,
              cashier_id: this.currUser.user_id,
              cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
              oar_receipt_no: this.receipt.oar_receipt_no,
              oar_number: this.receipt.oar_series_no,
              oar_service: 'Pera Bills',
              oar_currency: 'PHP',
              oar_principal_amount: overallAmount,
              oar_discount: 0,
              oar_total: overallTotal,
              grand_total: overallTotal,
              print_email_hold: 0,
              is_forex_logo: 0
            })

            if (saveReceipt.code === 200) {
              if (saveReceipt.result.receipt_status === 1) {
                renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
              } else if (saveReceipt.result.receipt_status === 2) {
                const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                  application_id: this.currUser.application_id,
                  ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                })
                var newBlob = new Blob([res], { type: 'application/pdf' })
                var newFileURL = URL.createObjectURL(newBlob)
                window.open(newFileURL, '_blank')
              } else {
                renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
              }
            } else {
              renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
            }
            this.renderToast('success', 'Multiple OAR Assignment Successful', 'Multiple OARs have been assigned successfully.')
          } else {
            const payload = {
              id: this.seriesDetail.id,
              status: 2,
              customer_id: this.currUser.user_id,
              encoded_by: this.currUser.user_id,
              date_encoded: format(new Date(), 'yyyy-MM-dd'),
              amount: overallTotal,
              description: 'Pera Bills',
              remarks: 'Pera Bills'
            }

            // Generates Manual Receipt with no print. Transaction is automatically saved on reports.
            await this.$store.dispatch('manualReceipt/updateSeriesNo', payload).then(res => {
              if (res.code >= 200 && res.code <= 299) {
                this.renderToast('success', 'Transaction Saved on Record', `Successfully Saved Transaction on Record - OAR Form Number: ${this.seriesDetail.series_no}`)
              } else {
                this.renderToast('error', 'Error', res.message)
              }
            }).catch(err => {
              this.renderToast('error', 'Error', err)
            })
          }
          this.getTransactions()
        } else {
          this.renderToast('error', 'Multiple OAR Assignment failed', 'There was a failure in assigning Multiple OARs, please try again.')
        }
      })
    },
    async reprintReceipt (item) {
      const tpaPayload = {
        bill_id: 2,
        location_id: this.currUser.location_id
      }

      let tpaId = ''

      await this.$store.dispatch('bills/getTPARequest', tpaPayload)
        .then(res => {
          tpaId = res.tpa_id
          this.printBillsReceipt(tpaId, item)
        }).catch(e => {
          console.log(e)
        })
    },
    async printBillsReceipt (tpaId, item) {
      const transactionRefNo = `TR#${item.transaction_id} ${this.currUser.user_name}/${tpaId}`
      const transactionTimestamp = `${format(new Date(item.created_at), 'yyyy-MM-dd HH:mm:SS')}`
      const getAPIRequest = JSON.parse(item.api_request)

      const data = {
        billerName: getAPIRequest.biller_name,
        transactionRefNo: transactionRefNo,
        transactionTimestamp: transactionTimestamp,
        referenceNo: item.reference_number,
        paymentMethod: item.payment_type === 0 ? 'CASH' : '',
        amount: item.amount,
        trxId: item.transaction_id,
        tpaId: tpaId
      }

      this.$store.commit('bills/SET_BILLS_PAYMENT_RECEIPT_DATA', data)

      setTimeout(function () {
        window.open('/pera-bills/receipt', '_blank')
      }, 2500)
    },
    async printOAR (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptID',
        {
          location_id: this.currUser.location_id,
          receipt_type: '2',
          or_series_no: '',
          oar_series_no: data.form_number,
          trx_date: this.currUser.trx_date
        }
      )
      this.getOARDetail(res.result)
    },
    async getOARDetail (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptByID', data.ereceipt_api_id)
      this.downloadOAR(res.result)
    },
    async downloadOAR (data) {
      var payload = { application_id: data.application_id, ereceipt_api_id: data.id }
      const res = await this.$store.dispatch('eReceipt/downloadEreceipt', payload)
      var newBlob = new Blob([res], { type: 'application/pdf' })
      var newFileURL = URL.createObjectURL(newBlob)
      window.open(newFileURL, '_blank')
    },
    async confirmDelete (data) {
      this.deletePayload = data
      this.deleteDialog = true
    },
    async deleteData () {
      await this.$store.dispatch('peraPay/deletePeraPayManualEntry', this.deletePayload.trx_bills_payment_temp_id).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.renderToast('success', 'Entry Deleted', 'The entry was successfully deleted.')
          this.deleteDialog = false
          this.getTransactions()
        } else {
          this.renderToast('error', 'Error', res.error.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    retrieveCIS () {
    }
  }
}
</script>
<style scoped>
/* .ph-nonex-datatable-title {
  padding-left: 0;
} */

.v-data-table.ph-nonex-datatable {
  /* background: #FFFFFF; */
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
}

.ph-nonex-sender-container,
.ph-nonex-receiver-container {
  display: flex;
}

.ph-nonex-table-avatar {
  margin: auto 0;
}

.ph-nonex-table-names-text,
.ph-nonex-status-text {
  margin: auto;
  margin-left: 10px;
}

.ph-nonex-status-circle {
  height: 10px;
  width: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto 0;
}

.ph-nonex-status-success {
  background: #46B746;
}

.ph-nonex-status-success-text {
  color: #46B746;
}

.ph-nonex-status-pending {
  background: #F76F34;
}

.ph-nonex-status-pending-text {
  color: #F76F34;
}

.ph-nonex-status-container {
  display: flex;
}

.ph-nonex-action-container {
  display: flex;
  flex-direction: row;
}

.ph-wu-logo {
  height: 25px;
  width: 10px;
}

.ph-nonex-logo {
  transform: scale(0.45);
  border-radius: 0;
}

.ph-fulfill-logo {
  transform: scale(0.35);
  border-radius: 0;
}

.ph-tooltip {
  background: #EDEDED;
  color: #000;
  border-radius: 50px;
}

.ph-primary-button {
  background: #FFF;
  color: #1A2791 !important;
  /* border: 2px solid #1A2791; */
  border-radius: 10px;
  font-family: 'Proxima Nova';
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 23px;
  transition: .2s;
  margin: 10px 0;
  margin-right: 10px;
}

.ph-primary-button:hover {
  background: #1A2791 !important;
  color: #fff !important;
}

.ph-primary-button.ph-primary-execute-btn {
  background: #1A2791;
  color: #fff !important;
  font-size: 14px;
}

.ph-primary-button.ph-primary-execute-btn:hover {
  background: #fff !important;
  color: #1A2791 !important;
  border: 2px solid #1A2791;
}

.ph-datatable-checked {
  margin-left: 2px;
}
</style>
