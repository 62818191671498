<template>
  <v-container fluid>
        <v-row class="mt-16 mb-4 pt-10 mx-2 mt-sm-4 pt-sm-0 mx-sm-2 mx-md-4" no-gutters>
          <v-col class="d-flex justify-start" cols="auto">
            <v-btn
              x-medium
              color="primary"
              elevation="0"
              class="md-12 ma-5 ph-action-button ph-cashcount-action-btn"
              outlined
              @click="$refs.fileInput.click()"
            >
              Choose File
            </v-btn>
            <input
              type="file"
              ref="fileInput"
              accept=".csv, .xlsx, .xls"
              @change="handleFileChange"
              style="display: none"
            />
          </v-col>

          <v-col class="d-flex justify-start" cols="auto">
            <h4
              class="ml-2 pb-5 pt-6 pb-md-5 primary--text"
              v-if="file !== null"
            >
              {{ file.name }}
            <v-btn
              icon
              color="red"
              @click="removeFile"
              class="ml-2"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </h4>
            <h4
              class="ml-2 pb-5 pt-6 pb-md-5 primary--text"
              v-else
            >
              No File Chosen
            </h4>
          </v-col>
        </v-row>
      <v-spacer></v-spacer>
      <v-row class="md-12 ma-5" no-gutters>
        <v-col :cols="6" class="d-flex justify-start">
          <v-text-field
            class="ph-textbox"
            label="File Name"
            outlined
            v-model="batchUpload.fileName"
            :rules="requiredRules"
            required
          />
        </v-col>
      </v-row>
      <v-row class="md-12 ma-5" no-gutters>
        <v-col :cols="6" class="d-flex justify-start">
          <p>
            Supported file formats: .csv, .xlsx, .xls
          </p>
        </v-col>
      </v-row>
      <v-row class="md-12 ma-5" no-gutters>
        <v-col :cols="2" class="d-flex justify-start">
          <v-btn
            @click="readFile"
            class="rounded-lg mt-md-4 font-weight-black"
            color="primary"
            block
            x-medium
          >
            Read File
          </v-btn>
        </v-col>
      </v-row>

        <v-row no-gutters class="d-flex justify-end align-center" v-if="withPhv && withPhv.length > 0">
        <v-col :cols="12" :md="2" class="d-flex justify-end align-center mr-1">
          <v-autocomplete
            v-model="selectedCategory"
            :items="category"
            item-value="value"
            label="Category"
            class="ph-textbox mt-md-4"
            outlined
          />
        </v-col>

        <v-col :cols="12" :md="2" class="d-flex justify-end align-center ml-1">
          <v-text-field
            label="Service Fee"
            class="ph-textbox mt-md-4"
            outlined
            disabled
          />
        </v-col>
      </v-row>

        <v-row no-gutters class="d-flex align-center" v-if="withPhv && withPhv.length > 0">
          <v-col :cols="12" :md="6" class="d-flex align-center">
          <h2 class="primary--text">
            With Perahub Visa Card
          </h2>
        </v-col>
        <v-col :cols="12" :md="6" class="d-flex justify-end align-center mb-4">
          <v-btn
            @click="proceedToCashIn"
            class="rounded-lg mt-md-4 font-weight-black"
            color="primary"
            x-medium
          >
            Proceed to Cash-In
          </v-btn>
        </v-col>
        </v-row>

        <v-row no-gutters class="d-flex align-center" v-if="withPhv && withPhv.length > 0">
        <v-col :cols="12">
          <v-data-table
            :headers="headers"
            class="custom-table"
            :calculate-widths="true"
            :items="withPhv"
            :items-per-page="5"
            item-key='id'
          >
          <template v-slot:item.action="{ item, index }">
              <v-checkbox
                v-model="selectedRowsWithPhv"
                :value="item"
                :key="index"
                :calculate-widths="true"
                hide-details
                color="primary"
                class="mb-4"
              />
          </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row class="md-12 ma-5" no-gutters v-if="withoutPhv && withoutPhv.length > 0">
        <v-col :cols="12" :md="11" class="d-flex align-center">
          <h2 class="primary--text">
            Without Perahub Visa Card
          </h2>
        </v-col>
        <v-col :cols="12">
          <v-data-table
            :headers="withoutUB"
            class="custom-table"
            :calculate-widths="true"
            :items="withoutPhv"
            :items-per-page="5"
            item-key='id'
          >
          <template v-slot:item.action="{ item, index }">
              <v-checkbox
                v-model="selectedRowsWithoutPhv"
                :value="item"
                :key="index"
                :calculate-widths="true"
                hide-details
                color="primary"
                class="mb-4"
              />
          </template>
          </v-data-table>
      </v-col>
      </v-row>

      <!-- OTP -->
      <v-dialog
      v-model="cashInOTPDialog"
      persistent
      :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '30%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="pb-0">
          <v-spacer />
          <v-btn @click="cashInOTPDialog = false" x-small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-md-4 text-center">
          <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
          <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
            OTP
          </h3>
          <p>
            Input your 6-digit One-time password PIN from the authentication app.
          </p>
          <br>
          <v-otp-input
            v-model="otp"
            id="otp"
            type="number"
            :disabled="isOTPLoading"
            @finish="onFinishOTP"
          />
          <p class="red--text">
            {{ otpMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  props: ['reloadStatus'],
  data () {
    return {
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      file: null,
      selectedCategory: '',
      cashInOTPDialog: false,
      isOTPLoading: false,
      otpInvalid: false,
      otp: '',
      otpMessage: '',
      selectedRowsWithPhv: [],
      selectedRowsWithoutPhv: [],
      selectedForCashInWithPhv: [],
      selectedForCashInWithoutPhv: [],
      withPhv: [],
      withoutPhv: [],
      headers: [
        {
          text: 'Customer Number',
          sortable: false,
          value: 'customer_number'
        },
        {
          text: 'First Name',
          sortable: false,
          value: 'first_name'
        },
        {
          text: 'Last Name',
          sortable: true,
          value: 'last_name'
        },
        {
          text: 'Middle Name',
          sortable: false,
          value: 'middle_name'
        },
        {
          text: 'UB Account Number',
          sortable: false,
          value: 'ub_account_no'
        },
        {
          text: 'UB Card Number',
          sortable: false,
          value: 'ub_card_no'
        },
        {
          text: 'Cashback Earned',
          sortable: true,
          value: 'cashback_earned'
        },
        {
          text: 'Mark for Cash-In',
          sortable: false,
          value: 'action'
        }
      ],
      withoutUB: [
        {
          text: 'Customer Number',
          sortable: false,
          value: 'customer_number'
        },
        {
          text: 'First Name',
          sortable: false,
          value: 'first_name'
        },
        {
          text: 'Last Name',
          sortable: true,
          value: 'last_name'
        },
        {
          text: 'Middle Name',
          sortable: false,
          value: 'middle_name'
        },
        {
          text: 'Cashback Earned',
          sortable: true,
          value: 'cashback_earned'
        },
        {
          text: 'Mark for Cash-In',
          sortable: false,
          value: 'action'
        }
      ],
      category: [
        {
          text: 'FindShare',
          value: 'FS'
        },
        {
          text: 'Loan Services Group',
          value: 'LSG'
        }
      ]
    }
  },
  watch: {
    // Storing the data of the transactions marked for cash in
    selectedRowsWithPhv (selectedData) {
      this.selectedForCashInWithPhv = selectedData
    },
    selectedRowsWithoutPhv (selectedData) {
      this.selectedForCashInWithoutPhv = selectedData
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      batchUpload: 'cashInBatchDisbursment/batchUpload',
      customerList: 'cashInBatchDisbursment/customerList'
    })
  },
  mounted () {
  },
  methods: {
    renderToast,
    handleFileChange (event) {
      const selectedFile = event.target.files[0]
      if (selectedFile) {
        this.file = selectedFile
        this.batchUpload.file = selectedFile
      }
    },
    // Clearing the data on the file and sheet name and in state
    removeFile () {
      this.$store.commit('cashInBatchDisbursment/SET_BATCH_UPLOAD_DEFAULT')
      this.$store.commit('cashInBatchDisbursment/SET_CUSTOMER_LIST_DEFAULT')
      this.file = null
      this.withPhv = []
      this.withoutPhv = []
      this.selectedRowsWithPhv = []
      this.selectedRowsWithoutPhv = []
      this.selectedForCashInWithPhv = []
      this.selectedForCashInWithoutPhv = []
      const fileInput = this.$refs.fileInput
      if (fileInput) {
        fileInput.value = ''
      }
    },
    // Open the OTP Dialog
    openVerifyOTP () {
      this.otp = ''
      this.cashInOTPDialog = true
    },
    // Check the OTP if valid
    onFinishOTP (pin) {
      this.otpMessage = ''
      this.isOTPLoading = true
      setTimeout(() => {
        this.doVerify(pin)
      }, 3500)
    },
    // Read CSV,XLSX,XLS files which will display on the table
    async readFile () {
      if (!this.batchUpload.file) { // Checks if a file is chosen
        this.renderToast('error', 'File Required', 'Please choose a file.')
      } else if (!this.batchUpload.fileName) { // Checks if sheet name is entered
        this.renderToast('error', 'Sheet Name Required', 'Please enter a sheet name.')
      } else {
        this.$store.commit('app/TOGGLE_LOADING', true)
        const payload = new FormData()
        payload.append('file', this.batchUpload.file)
        payload.append('sheet', this.batchUpload.fileName)
        await this.$store.dispatch('cashInBatchDisbursment/readCashInFile', payload).then(res => {
          if (res.data.code === 200) {
            this.$store.commit('app/TOGGLE_LOADING', false)
            this.$store.commit('cashInBatchDisbursment/SET_CUSTOMER_LIST', res.data.result)
            const filteredCustomerList = this.customerList.map((customer, index) => {
              customer.tag = (customer.ub_account_no || customer.ub_account_no === '') ? 1 : 0
              customer.id = index + 1
              return customer
            })
            this.withPhv = filteredCustomerList.filter(customer => customer.tag === 1)
            this.withoutPhv = filteredCustomerList.filter(customer => customer.tag === 0)
            this.renderToast('success', 'File Reading Successful', 'The file was successfully read.')
          } else {
            this.renderToast('error', 'Error', res.error.message)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    // Opens OTP for Cash In & checks if category is selected and a transaction is selected
    async proceedToCashIn () {
      if (this.selectedForCashInWithPhv.length !== 0 || this.selectedForCashInWithoutPhv.length !== 0) {
        if (this.selectedCategory !== '') {
          this.openVerifyOTP()
        } else {
          this.renderToast('error', 'No Category Selected', 'Please select a Category')
        }
      } else {
        this.renderToast('error', 'No Transaction(s) selected for Cash In', 'Please select the transaction(s) for Cash In.')
      }
    },
    // Verify the input OTP
    async doVerify (pin) {
      this.$store.commit('app/TOGGLE_LOADING', true)
      this.otp = pin
      if (this.otp && this.otp.length === 6) {
        this.processCashIn(pin)
      }
    },
    // Process the Selected transaction for Cash In
    async processCashIn (pin) {
      // combining the selected transactions into one for payload
      const cashInData = [
        ...this.selectedForCashInWithPhv.flat(),
        ...this.selectedForCashInWithoutPhv.flat()
      ]
      cashInData.forEach(item => {
        delete item.id
      })
      const payload = {
        user_id: this.currUser.user_id,
        otp: pin,
        category: this.selectedCategory,
        trx_date: this.currUser.trx_date,
        location_id: this.currUser.location_id,
        user_code: this.currUser.user_name,
        customer_data: cashInData
      }
      await this.$store.dispatch('cashInBatchDisbursment/submitCashIn', payload).then(res => {
        if (res.data.code === 200) {
          this.$store.commit('app/TOGGLE_LOADING', false)
          this.cashInOTPDialog = false
          this.isOTPLoading = false
          this.reloadStatus()
          this.renderToast('success', 'Cash In Successful', 'The Selected Transactions are Cashed In Successfully.')
        } else {
          this.cashInOTPDialog = false
          this.isOTPLoading = false
          this.renderToast('error', 'Error', res.data.message)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
