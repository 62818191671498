<template>
  <v-container fluid class='ph-nonex-container' v-if="currentReport !== null">
    <v-breadcrumbs class='px-2 px-md-1 mx-md-4' :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row>
      <v-col cols="12" md="3">
        <v-form ref="reportForm">
          <v-col cols="12">
            <p>Please complete the form to generate report</p>
          </v-col>
          <v-col cols="12" v-for="field in fields" :key="field.prop">
            <v-text-field
              v-if="field.type === 'text'"
              :ref="`text_${field.prop}`"
              v-model="formData[field.prop]"
              :label="field.label"
              color="gray darken-1"
              class="ph-textbox"
              :rules="field.rules"
              outlined
            />
            <v-menu
              :ref="`datePicker_${field.prop}`"
              v-model="datePicker[field.prop]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-if="field.type === 'date'"
                  v-model="formData[field.prop]"
                  class="ph-textbox"
                  color="gray darken-1"
                  :label="field.label"
                  append-icon="mdi-calendar"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="field.rules"
                  clearable
                />
              </template>
              <v-date-picker
                v-model="formData[field.prop]"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                @change="syncDate(formData[field.prop], field.prop)"
              />
            </v-menu>
            <v-autocomplete
              v-if="showDropdownField(field)"
              :ref="`dropdown_${field.prop}`"
              v-model="formData[field.prop]"
              :items="sources[field.source]"
              :label="field.label"
              :rules="field.rules"
              class="ph-textbox"
              return-object
              outlined
            />
          </v-col>
        </v-form>
        <v-col cols="12">
          <v-btn
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn"
            @click="generateReport"
          >
            View Report
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { renderToast, camelCaseToSnakeCase } from '@/utils'

export default {
  computed: {
    ...mapGetters({
      collections: 'collections',
      reportsStructure: 'report/reportsStructure',
      collectionProviders: 'dali/collectionProviders',
      currUser: 'auth/currUser',
      remcos: 'nonex/remcos'
    }),
    breadcrumbsItems () {
      const items = [
        {
          text: 'Dashboard',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Reports',
          disabled: false,
          exact: true,
          to: '/reports'
        },
        {
          text: this.currentReport?.rpt_name,
          disabled: true
        }
      ]

      return items
    },
    availableReports () {
      if (this.reportsStructure) {
        const reportCategories = Object.values(this.reportsStructure)
        const allReports = []

        reportCategories.forEach(reports => {
          for (let i = 0; i < reports.length; i++) {
            allReports.push(reports[i])
          }
        })
        return allReports
      }
      return []
    },
    currentReport () {
      let report = null
      this.availableReports.forEach(item => {
        if (item.rpt_value === this.$route.params.report) report = item
      })

      return report
    },
    fields () {
      if (this.currentReport) {
        const fields = JSON.parse(this.currentReport.rpt_params)

        return fields.map(field => {
          const rules = []
          const rulesStrings = field.rules.split('|')
          const rulesSet = {
            required: v => (v !== null && v !== undefined && v !== '') || 'This field is required',
            date: v => /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(v) || 'Invalid date format',
            number: v => /^\d+$/.test(v) || 'Not a valid number'
          }

          rulesStrings.forEach(rule => {
            if (rulesSet[rule]) {
              if (rule === 'required') rules.unshift(rulesSet[rule])
              else rules.push(rulesSet[rule])
            }
          })

          return { ...field, rules }
        })
      }
      return []
    }
  },
  data () {
    return {
      datePicker: {},
      formData: {},
      sources: {
        locations: [
          { text: 'ALL', value: '0' },
          { text: 'ALL COW', value: '01' },
          { text: 'ALL PRP', value: '03' }
        ],
        policies: [
          { text: 'ALL', value: '' }
        ],
        cicoProviders: [],
        cicoTrxTypes: [
          { text: 'Cash In', value: 'Cash In' },
          { text: 'Cash Out', value: 'Cash Out' }
        ],
        peraBankProviders: [
          { text: 'BANK OF THE PHIL. ISLANDS', value: 0 },
          { text: 'UNION BANK OF THE PHILS.', value: 1 },
          { text: 'METROBANK', value: 30 }
        ],
        peraBankTrxTypes: [
          { text: 'CASH IN', value: 1 }
        ],
        currencies: [
          { text: 'PESO', value: 1 },
          { text: 'US DOLLAR', value: 2 }
        ],
        promoTypes: [
          { text: 'Auto Incentive', value: '0' }
        ],
        daliProviders: [],
        bayadBillers: [],
        remcos: [],
        pettyCashExpenses: [],
        eLoadCategory: [],
        categories: [
          { text: 'Detailed', value: 'D' },
          { text: 'Summary', value: 'S' }
        ],
        cardType: [
          { text: 'By Location', value: 'byloc' },
          { text: 'By Group', value: 'bygrp' }
        ],
        peraLoanTypes: [
          { text: 'Pension Loans', value: 'P' },
          { text: 'Teachers Loans', value: 'D' },
          { text: 'Betterway Downpayments', value: 'BW' }
        ],
        phvCardTypes: [
          { text: 'PHV Card', value: 'PeraHubCard' },
          { text: 'Regular EON', value: 'Regular' }
        ],
        journalEReceiptTypes: [
          { text: 'Consolidated', value: 'rpt_jou_con' },
          { text: 'By Branch', value: 'rpt_jou_br' }
        ],
        groupType: [
          { text: 'By Date', value: 1 },
          { text: 'By Location', value: 2 }
        ],
        cicoType: [
          { text: 'USP Retail Channel', value: 'usp' },
          { text: 'Mobile Retail Channel', value: 'yondu' },
          { text: 'ECPay Retail Channel', value: 'ecpay' }
        ]
      }
    }
  },
  methods: {
    showDropdownField (field) {
      if (field.type === 'dropdown') {
        if (field.prop === 'locationId') {
          return (String(this.currUser.location_type) === '2')
        }
        return true
      }
      return false
    },
    syncDate (date, prop) {
      if (date && prop) {
        this.datePicker[prop] = false
        if (this.$refs[`datePicker_${prop}`] && Object.prototype.hasOwnProperty.call(this.$refs[`datePicker_${prop}`], 'save')) {
          this.$refs[`datePicker_${prop}`].save(date)
        }
      }
    },
    generateReport () {
      if (this.$refs?.reportForm?.validate()) {
        const payload = {}
        this.fields.forEach(field => {
          switch (field.prop) {
            case 'locationId':
              if (String(this.currUser.location_type) === '2') {
                payload.location_name = this.formData[field.prop].text
                payload.location_id = this.formData[field.prop].value
              } else {
                payload.location_name = this.currUser.location_name
                payload.location_id = this.currUser.location_id
              }
              break
            case 'billId':
            case 'remcoId':
            case 'bankId':
            case 'trxType':
            case 'currencyId':
            case 'promoCode':
            case 'provider':
            case 'policy':
              // Added Condition for covid summary and eternal reports
              if (this.currentReport.rpt_value === 'pera_protect_sales_report_covid_protect_summary' || this.currentReport.rpt_value === 'pera_protect_sales_report_detailed' || this.currentReport.rpt_value === 'pera_protect_sales_report_summary') {
                if (this.formData[field.prop].text === 'ALL') {
                  payload.policy = this.formData[field.prop].value
                } else {
                  payload.policy = this.formData[field.prop].product_type
                }
              } else { // handling of other insurance reports
                payload[camelCaseToSnakeCase(field.prop)] = this.formData[field.prop].value
              }
              break
            case 'is_cash':
            case 'status':
              payload[camelCaseToSnakeCase(field.prop)] = this.formData[field.prop].value
              break
            case 'type':
              if (this.currentReport.rpt_value === 'sales_report_journal_EReceipt') {
                payload.type = this.formData[field.prop].value
              }
              break
            case 'groupType':
              if (this.currentReport.rpt_value === 'first_customer_wu_buy_back_transaction_summary') {
                payload.type = this.formData[field.prop].value
              }
              break
            case 'cicoType': // payload for client code using CICO Type
              if (this.currentReport.rpt_value === 'sales_gateway_cico_detailed') {
                payload.client_code = this.formData[field.prop].value
              }
              if (this.currentReport.rpt_value === 'sales_gateway_eload_detailed' || this.currentReport.rpt_value === 'sales_gateway_eload_summary') {
                payload.client_code = this.formData[field.prop].value
              }
              break
            case 'eLoadCategory':
              payload.eload_category = this.formData[field.prop].value
              break
            case 'pettyCashExpenses': // payload for Branch Expenses
              payload.filter = this.formData[field.prop].value
              break
            default:
              payload[camelCaseToSnakeCase(field.prop)] = this.formData[field.prop]
              break
          }
        })

        if (['sales_insurance_malayan', 'sales_insurance_dpp', 'sales_insurance_covid', 'sales_insurance_dppv2', 'dppv2_roh_report', 'dppv2_spa_report', 'sales_insurance_microsafe', 'sales_insurance_payapav2', 'member_sales_microsafe', 'member_sales_payapav2'].includes(this.currentReport.rpt_value)) {
          if (this.currentReport.rpt_value === 'sales_insurance_malayan') payload.provider = 'COC'
          if (this.currentReport.rpt_value === 'sales_insurance_dpp') payload.provider = 'COC3'
          if (this.currentReport.rpt_value === 'sales_insurance_covid') payload.provider = 'COC6'
          if (this.currentReport.rpt_value === 'sales_insurance_dppv2' || this.currentReport.rpt_value === 'dppv2_roh_report' || this.currentReport.rpt_value === 'dppv2_spa_report') payload.provider = 'COC8'
          payload.location_type = this.currUser.location_type
          if (this.currentReport.rpt_value === 'sales_insurance_payapav2' || this.currentReport.rpt_value === 'member_sales_payapav2') payload.provider = 'COC9'
          if (this.currentReport.rpt_value === 'sales_insurance_microsafe' || this.currentReport.rpt_value === 'member_sales_microsafe') payload.provider = 'COC10'
        }

        if (this.currentReport.rpt_value === 'digital_services_promo_rpt') {
          payload.currency_id = 1
          payload.bank_id = 1
          payload.user_id = this.currUser.user_id
        }

        if (this.currentReport.rpt_value === 'trxlogs') payload.user_id = this.currUser.user_id
        if (this.currentReport.rpt_value === 'ctpl_report') payload.category = 'Detailed'
        // Handlers for New Reports
        if (this.currentReport.rpt_value === 'pera_travel_sales_report_summary') payload.category = 'Summary'
        if (this.currentReport.rpt_value === 'sales_gateway_eload_summary') payload.category = 'Summary'
        if (this.currentReport.rpt_value === 'pera_protect_sales_report_detailed' || this.currentReport.rpt_value === 'pera_protect_sales_report_summary') payload.coc = 'COC4'
        if (this.currentReport.rpt_value === 'pera_protect_sales_report_malayan_summary') payload.coc = 'COC'
        if (this.currentReport.rpt_value === 'pera_protect_sales_report_dengue_protect_summary') payload.coc = 'COC3'
        if (this.currentReport.rpt_value === 'pera_protect_sales_report_covid_protect_summary') payload.coc = 'COC6'

        if (this.currentReport.rpt_value === 'sales_bayad') payload.coy = 'usp'
        if (this.currentReport.rpt_value === 'sales_eon_cico') payload.location_type = this.currUser.location_type

        if (this.currentReport.rpt_value === 'sales_wupay' || this.currentReport.rpt_value === 'sales_peraload' || this.currentReport.rpt_value === 'sales_peraloan' || this.currentReport.rpt_value === 'sales_peracard') {
          payload.card_type = payload.card_type.value
        }

        if (this.currentReport.rpt_value === 'coh') {
          payload.user_id = this.currUser.user_id
          payload.location_name = this.currUser.location_name
        }

        if (this.currentReport.rpt_value === 'sales_peraloan') {
          if (payload.card_type === 'D') {
            payload.service_code = 'dloan'
          } else if (payload.card_type === 'P') {
            payload.service_code = 'ploan'
          } else if (payload.card_type === 'BW') {
            payload.service_code = 'bloan'
          }
        }
        // temporary - for conditioning.
        // payload.location_id = this.currUser.location_id
        // payload.location_type = this.currUser.location_type
        // payload.location_id = '8'
        // payload.location_type = '2'

        // default type of report
        payload.category = 'Detailed'
        payload.rpt_value = this.currentReport.rpt_value
        const convertedData = Buffer.from(JSON.stringify(payload)).toString('base64')
        window.open(`/reports/${this.$route.params.report}/view?q=${convertedData}`, '_blank')
      } else {
        renderToast('error', 'Incomplete Fields', 'Please complete the form to continue')
      }
    }
  },
  async beforeMount () {
    console.log(this.currentReport)

    if (this.reportsStructure.length === 0) await this.$store.dispatch('report/getRptTypes')

    if (this.currentReport === null && this.availableReports.length > 0) {
      renderToast('error', 'Report Error', 'The report is not available')
      this.$router.push('/reports')
    }

    await this.$store.dispatch('getAllLocations', 0)
    this.sources.locations = [
      ...this.sources.locations,
      ...this.collections.locations.map(item => {
        return {
          text: item.location_name,
          value: item.location_id
        }
      })
    ]

    await this.$store.dispatch('nonex/getRemcos')
    this.sources.remcos = [
      ...this.remcos.data.map(item => {
        return {
          text: item.non_ex_name,
          value: item.id
        }
      })
    ]

    const cicoProviders = await this.$store.dispatch('cico/getCICOProviders')
    this.sources.cicoProviders = [
      ...cicoProviders.result
        .filter(item => item.provider_code !== 'PHV' && item.provider_code !== 'EON')
        .map(item => {
          return {
            text: item.provider_name,
            value: item.provider_code
          }
        })
    ]

    const daliProviders = await this.$store.dispatch('bills/getOtherProvider', { location_id: this.currUser.location_id })
    this.sources.daliProviders = [
      ...daliProviders.result.map(item => {
        return {
          text: item.provider_name,
          value: item.provider_code
        }
      })
    ]

    const bayadBillers = await this.$store.dispatch('billers/getBillers')
    this.sources.bayadBillers = [
      ...bayadBillers.result.map(item => {
        return {
          text: item.biller_name,
          value: item.id
        }
      })
    ]

    // Populating for Branch Expenses Detailed
    const pettyCashExpenses = await this.$store.dispatch('adjustmentAndExpenses/getTypes')
    this.sources.pettyCashExpenses = [
      ...pettyCashExpenses.result.map(item => {
        return {
          text: item.category_name,
          value: item.category_id
        }
      })
    ]

    // Populating eLoad Categories
    if (this.currentReport.rpt_value === 'sales_gateway_eload_detailed' || this.currentReport.rpt_value === 'sales_gateway_eload_summary') {
      const eLoadCategory = await this.$store.dispatch('report/getEloadCategory')
      this.sources.eLoadCategory = [
        ...eLoadCategory.result.map(item => {
          return {
            text: item.category
              .replace(/_/g, ' ')
              .replace(/\b\w/g, char => char.toUpperCase()),
            value: item.category
          }
        })
      ]
      await this.$store.dispatch('report/getRptTypes')
    }

    if (['sales_insurance_malayan', 'sales_insurance_dpp', 'sales_insurance_covid', 'sales_insurance_dppv2', 'dppv2_roh_report', 'dppv2_spa_report', 'sales_insurance_microsafe', 'sales_insurance_payapav2', 'member_sales_payapav2', 'member_sales_microsafe', 'pera_protect_sales_report_detailed', 'pera_protect_sales_report_summary', 'pera_protect_sales_report_malayan_summary', 'pera_protect_sales_report_dengue_protect_summary', 'pera_protect_sales_report_covid_protect_summary'].includes(this.$route.params.report)) {
      let category = ''

      if (this.$route.params.report === 'sales_insurance_malayan' || this.$route.params.report === 'pera_protect_sales_report_malayan_summary') category = 'COC'
      if (this.$route.params.report === 'sales_insurance_dpp' || this.$route.params.report === 'pera_protect_sales_report_dengue_protect_summary') category = 'COC3'
      if (this.currentReport.rpt_value === 'pera_protect_sales_report_detailed' || this.currentReport.rpt_value === 'pera_protect_sales_report_summary') category = 'COC4'
      if (this.$route.params.report === 'sales_insurance_covid' || this.$route.params.report === 'pera_protect_sales_report_covid_protect_summary') category = 'COC6'
      if (this.$route.params.report === 'sales_insurance_dppv2' || this.$route.params.report === 'dppv2_roh_report' || this.$route.params.report === 'dppv2_spa_report') category = 'COC8'
      if (this.$route.params.report === 'sales_insurance_payapav2' || this.$route.params.report === 'member_sales_payapav2') category = 'COC9'
      if (this.$route.params.report === 'sales_insurance_microsafe' || this.$route.params.report === 'member_sales_microsafe') category = 'COC10'

      if (this.$route.params.report === 'sales_insurance_dpp' || this.$route.params.report === 'pera_protect_sales_report_dengue_protect_summary') {
        const { result } = await this.$store.dispatch('insurance/getPolicies', { category })
        this.sources.policies = [
          ...this.sources.policies,
          ...result.map(item => {
            return {
              text: item.policy_name,
              value: item.product_type
            }
          })
        ]
      }
      if (this.$route.params.report === 'sales_insurance_dppv2' || this.$route.params.report === 'dppv2_roh_report' || this.$route.params.report === 'dppv2_spa_report') {
        const { result } = await this.$store.dispatch('insurance/getPolicies', { category })
        this.sources.policies = [
          ...this.sources.policies,
          ...result.map(item => {
            return {
              text: item.policy_name,
              value: item.product_type
            }
          })
        ]
      }
      if (this.$route.params.report === 'sales_insurance_microsafe') {
        const { result } = await this.$store.dispatch('insurance/getPolicies', { category })
        this.sources.policies = [
          ...this.sources.policies,
          ...result.map(item => {
            return {
              text: item.policy_name,
              value: item.product_type
            }
          })
        ]
      }
      if (this.$route.params.report === 'sales_insurance_payapav2') {
        const { result } = await this.$store.dispatch('insurance/getPolicies', { category })
        this.sources.policies = [
          ...this.sources.policies,
          ...result.map(item => {
            return {
              text: item.policy_name,
              value: item.product_type
            }
          })
        ]
      }
      if (this.$route.params.report === 'member_sales_payapav2') {
        const { result } = await this.$store.dispatch('insurance/getPolicies', { category })
        this.sources.policies = [
          ...this.sources.policies,
          ...result.map(item => {
            return {
              text: item.policy_name,
              value: item.product_type
            }
          })
        ]
      }
      if (this.$route.params.report === 'member_sales_microsafe') {
        const { result } = await this.$store.dispatch('insurance/getPolicies', { category })
        this.sources.policies = [
          ...this.sources.policies,
          ...result.map(item => {
            return {
              text: item.policy_name,
              value: item.product_type
            }
          })
        ]
      }
      // Getting Policies for Eternal Plan for detailed/summary
      if (this.$route.params.report === 'pera_protect_sales_report_detailed' || this.$route.params.report === 'pera_protect_sales_report_summary') {
        const { result } = await this.$store.dispatch('insurance/getPolicies', { category })
        this.sources.policies = [
          ...this.sources.policies,
          ...result.map(item => {
            return {
              text: item.policy_name,
              value: item.id,
              product_type: item.product_type
            }
          })
        ]
      }
      // Getting Policies for Malayan Plan
      if (this.$route.params.report === 'pera_protect_sales_report_malayan_summary') {
        const { result } = await this.$store.dispatch('insurance/getPolicies', { category })
        this.sources.policies = [
          ...result.map(item => {
            return {
              text: item.policy_name,
              value: parseInt(item.product_type, 10)
            }
          })
        ]
      }
      // Getting Policies for Covid Protect
      if (this.$route.params.report === 'pera_protect_sales_report_covid_protect_summary') {
        const { result } = await this.$store.dispatch('insurance/getPolicies', { category })
        this.sources.policies = [
          ...result.map(item => {
            return {
              text: item.policy_name,
              value: item.id,
              product_type: item.product_type
            }
          })
        ]
      }
    }
  }
}
</script>
