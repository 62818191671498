import { endpoints } from '@/api/endpoints'

const rpt = endpoints.use('reports')

const getRptTotalRemco = (context, payload) => new Promise((resolve, reject) => {
  setTimeout(() => {
    rpt.getRptTotalRemco(payload.trxDate, payload.locationID)
      .then(response => {
        context.commit('SET_REMCO_DATA', response.data.result)
        resolve(response.data.result)
      }).catch(err => {
        reject(err.response)
      })
  }, 2000)
})

const getRptTotalRH = (context, payload) => new Promise((resolve, reject) => {
  setTimeout(() => {
    rpt.getRptTotalRH(payload.trxDate, payload.groupLocation)
      .then(response => {
        context.commit('SET_RH_DATA', response.data.result)
        resolve(response.data.result)
      }).catch(err => {
        reject(err.response)
      })
  }, 2000)
})

const getRptCompAging = ({ commit }, { id, userID }) => new Promise((resolve, reject) => {
  rpt.getRptCompAging(id, userID)
    .then(({ data }) => {
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
})

const getRptUserBalanceTotal = (context, payload) => new Promise((resolve, reject) => {
  setTimeout(() => {
    rpt.getRptUserBalanceTotal(payload.locationID, payload.trxDate, payload.userID)
      .then(response => {
        context.commit('SET_USER_BAL_DATA', response.data.result)
        resolve(response.data.result)
      }).catch(err => {
        reject(err.response)
      })
  }, 2000)
})

const getRptTypes = (context, payload) => new Promise((resolve, reject) => {
  // setTimeout(() => {
  rpt.getRptTypes()
    .then(({ data }) => {
      context.commit('SET_REPORTS_STRUCTURE', data.result)
      resolve(data)
    }).catch(err => {
      reject(err.response)
    })
  // }, 2000)
})

const getEloadCategory = (context, payload) => new Promise((resolve, reject) => {
  // setTimeout(() => {
  rpt.getEloadCategory()
    .then(({ data }) => {
      context.commit('SET_REPORTS_STRUCTURE', data.result)
      resolve(data)
    }).catch(err => {
      reject(err.response)
    })
  // }, 2000)
})

const createRptType = (context, payload) => new Promise((resolve, reject) => {
  setTimeout(() => {
    rpt.createRptType(payload)
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err.response)
      })
  }, 2000)
})

const generateReport = (context, { endpoint, payload, method }) => new Promise((resolve, reject) => {
  rpt.generateReport(endpoint, payload, method)
    .then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err.response)
    })
})

const generateCompAgingReport = (context, payload) => new Promise((resolve, reject) => {
  rpt.getRptCompAging(payload.endpoint, payload.api_payload)
    .then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err.response)
    })
})

export default {
  getRptTotalRemco,
  getRptTotalRH,
  getRptUserBalanceTotal,
  getRptCompAging,
  getRptTypes,
  createRptType,
  generateReport,
  generateCompAgingReport,
  getEloadCategory
}
