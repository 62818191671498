<template>
  <div class="ph-container">
    <v-container fluid>
      <v-breadcrumbs
      :items="items"
      >
      </v-breadcrumbs>
      <v-tabs v-model="tab" class="mb-4">
        <v-tab
          :class="{
            'primary--text font-weight-black': tab === 0,
            'grey--text': tab !== 0
          }"
        >
          Batch Upload
        </v-tab>
        <v-tab
          :class="{
            'primary--text font-weight-black': tab === 1,
            'grey--text': tab !== 1
          }"
        >
          Status
        </v-tab>
        <v-tab-item>
        <CashInDisbBatchUpload :reloadStatus="reloadStatus"/>
        </v-tab-item>

        <v-tab-item>
          <CashInDisbStatus ref="cashInStatus" />
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CashInDisbBatchUpload from '../../components/CashInBatchDisbursement/CashInDisbBatchUpload.vue'
import CashInDisbStatus from '../../components/CashInBatchDisbursement/CashInDisbStatus.vue'

export default {
  components: {
    CashInDisbBatchUpload,
    CashInDisbStatus
  },
  async mounted () {
    await this.$store.dispatch('manualReceipt/getBranchList')
  },
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Admin',
          disabled: false,
          href: '#'
        },
        {
          text: 'Pera Cash-In Batch Disbursement',
          disabled: false,
          href: '#'
        }
      ],
      tab: 0,
      cashInStatus: false
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  methods: {
    reloadStatus () {
      this.$refs.cashInStatus.refreshGrids()
    }
  }
}
</script>

<style scoped>
.v-tab {
  font-size: 14px;
  font-weight: bold;
}

.v-tab.primary--text {
  font-size: 14px;
  font-weight: bold;
}

.v-tabs .v-tab--active {
  border-bottom: none !important;
}
</style>
