import apigee from '@/api/apigee'

export default {
  readCashInFile (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/CIBD-upload', payload)
  },
  submitCashIn (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/CIBD-insert', payload)
  },
  retryCashIn (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/CIBD-retry', payload)
  },
  reProcessCashIn (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/CIBD-reprocess', payload)
  },
  successfulCashInBatchDisbGrid (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/CIBD-success-grid?trx_date=${payload.trx_date}&location_id=${payload.location_id}`)
  },
  pendingCashInBatchDisbGrid (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/CIBD-pending-grid?trx_date=${payload.trx_date}&location_id=${payload.location_id}`)
  },
  reProcessCashInBatchDisbGrid (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/CIBD-reprocess-grid?trx_date=${payload.trx_date}&location_id=${payload.location_id}`)
  }
}
