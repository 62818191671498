import { render, staticRenderFns } from "./ReceiveMoneyWUForm.vue?vue&type=template&id=9584d054&scoped=true"
import script from "./ReceiveMoneyWUForm.vue?vue&type=script&lang=js"
export * from "./ReceiveMoneyWUForm.vue?vue&type=script&lang=js"
import style0 from "./ReceiveMoneyWUForm.vue?vue&type=style&index=0&id=9584d054&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9584d054",
  null
  
)

export default component.exports