import { endpoints } from '@/api/endpoints'

const cibd = endpoints.use('cashInBatchDisbursement')

export default {
  readCashInFile: (context, payload) => new Promise((resolve, reject) => {
    setTimeout(() => {
      cibd.readCashInFile(payload, payload)
        .then(response => {
          resolve(response)
        }).catch(err => {
          reject(err.response)
        })
    }, 2000)
  }),

  submitCashIn: (context, payload) => new Promise((resolve, reject) => {
    setTimeout(() => {
      cibd.submitCashIn(payload, payload)
        .then(response => {
          resolve(response)
        }).catch(err => {
          reject(err.response)
        })
    }, 2000)
  }),
  retryCashIn: (context, payload) => new Promise((resolve, reject) => {
    setTimeout(() => {
      cibd.retryCashIn(payload, payload)
        .then(response => {
          resolve(response)
        }).catch(err => {
          reject(err.response)
        })
    }, 2000)
  }),
  reProcessCashIn: (context, payload) => new Promise((resolve, reject) => {
    setTimeout(() => {
      cibd.reProcessCashIn(payload, payload)
        .then(response => {
          resolve(response)
        }).catch(err => {
          reject(err.response)
        })
    }, 2000)
  }),
  successfulCashInBatchDisbGrid: (context, payload) => new Promise((resolve, reject) => {
    setTimeout(() => {
      cibd.successfulCashInBatchDisbGrid(payload)
        .then(response => {
          resolve(response)
        }).catch(err => {
          reject(err.response)
        })
    }, 2000)
  }),
  pendingCashInBatchDisbGrid: (context, payload) => new Promise((resolve, reject) => {
    setTimeout(() => {
      cibd.pendingCashInBatchDisbGrid(payload)
        .then(response => {
          resolve(response)
        }).catch(err => {
          reject(err.response)
        })
    }, 2000)
  }),
  reProcessCashInBatchDisbGrid: (context, payload) => new Promise((resolve, reject) => {
    setTimeout(() => {
      cibd.reProcessCashInBatchDisbGrid(payload)
        .then(response => {
          resolve(response)
        }).catch(err => {
          reject(err.response)
        })
    }, 2000)
  })
}
