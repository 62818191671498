import api from '@/api'
import apigee from '@/api/apigee'

export default {
  sourceOfFund () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/source-of-fund')
  },
  purposeOfTransaction (remcoID) {
    let endpoint = '/uspdata/v1/usp-maintenance/collections/purpose-of-transaction'

    if (remcoID && remcoID !== 0) endpoint = `${endpoint}?remco_id=${remcoID}`

    return apigee.post(endpoint)
  },
  occupation (remcoID) {
    let endpoint = '/uspdata/v1/usp-maintenance/collections/occupation'

    if (remcoID && remcoID !== 0) endpoint = `${endpoint}?remco_id=${remcoID}`

    return apigee.post(endpoint)
  },
  relationship () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/relationship')
  },
  relationshipdppv2 () {
    return apigee.get('/uspdata/v1/usp-maintenance/dppv2-relationship')
  },
  relationshipRuralNet () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/rural-net-relationship')
  },
  idList (remcoID = 0) {
    return apigee.get(`/uspdata/v1/usp-maintenance/collections/id-list?remco_id=${remcoID}`)
  },
  countries () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/countries')
  },
  d2bcorridors () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/d2b-corridors')
  },
  wudascountries (payload) {
    return api.post('/v1/remit/wu/wu/collection/wudas', payload)
  },
  natureOfWork () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/nature-of-work')
  },
  positionLevel () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/position-level')
  },
  employment () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/employment')
  },
  cities () {
    return apigee.get('/uspdata/v1/usp-maintenance/maintenance/city')
  },
  barangay (city) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/barangay/${city}`)
  },
  d2bcountries () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/d2b-countries')
  },
  locations (locationType) {
    return apigee.get(`/uspdata/v1/usp-maintenance/locations-all?location_type=${locationType}`)
  },
  modules (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/user-management/retrieve-module', payload)
  },
  qpCompanies () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/qp-companies')
  },
  qpStateCities (countryCode) {
    return apigee.get(`/uspdata/v1/usp-maintenance/collections/statelist?country_code=${countryCode}`)
  },
  stateAndCities (countryCode) {
    return apigee.get(`/uspdata/v1/usp-maintenance/collections/statelist?country_code=${countryCode}`)
  },
  qrCode (locationID) {
    return apigee.get(`/uspdata/v1/usp-maintenance/staging/generate-qr/${locationID}`)
  },
  providers () {
    return apigee.get('/uspdata/v1/usp-maintenance/trxlimit/providers')
  },
  // policies for PRP malayan
  getPRPPolicies () {
    return apigee.get('/uspdata/v1/usp-maintenance/collections/get-insurance-policies')
  },
  // request for PRP
  requestAdjustment (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/trx-save-edit-adjustment', payload)
  }
}
