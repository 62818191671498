import { endpoints } from '@/api/endpoints'

const getModules = endpoints.use('userModules')

export default {
  getUserModules: ({ commit }, payload) => new Promise((resolve, reject) => {
    getModules.getUserModules(payload)
      .then(({ data }) => {
        commit('SET_USER_MODULES', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}
