<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
      :items="items"
      divider=">"
      ></v-breadcrumbs>
      <v-row  class="mt-md-4 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12" :sm="3" :md="3">
          <v-text-field
          class="ph-textbox"
          placeholder="Search Transaction"
          append-icon="mdi-magnify"
          hide-details
          outlined
          v-model="searchText"
          />
        </v-col>
        <!-- <v-col class="ml-4" :cols="12" :sm="2" :md="2">
          <v-btn
          rounded
          class="ph-textbox"
          color="primary"
          outlined
          large
          >
          <v-icon>mdi-view-list</v-icon>
          List view
        </v-btn>
        </v-col>
        <v-col :cols="12" :sm="2" :md="2">
          <v-btn
          class="ph-textbox"
          rounded
          color="primary"
          outlined
          large
          >
          <v-icon>mdi-view-grid</v-icon>
          Grid view
        </v-btn>
        </v-col> -->
      </v-row>
      <v-row class="mb-md-10 ma-5" no-gutters>
        <v-col :cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-data-table
              :headers="headers"
              :items="gridList"
              class="custom-table"
              :calculate-widths="true"
              :search="searchText"
            >
            <template v-slot:[`item.action`]="{ item }">
            <v-tooltip v-if="item.status === '2' || item.status === '3'">
              <template v-slot:activator="{on, attrs}">
                <v-btn v-bind="attrs" v-on="on" @click="viewDetails(item)" color="primary">
                  View Details
                </v-btn>
              </template>
              <span>View Details</span>
            </v-tooltip>
              <v-tooltip v-if="item.status === '0'">
                <template v-slot:activator="{on, attrs}">
                  <v-btn v-bind="attrs" v-on="on" @click="processApproval(item, 'approve', true)" color="primary" icon>
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </template>
                <span>Approve</span>
              </v-tooltip>
              <v-tooltip v-if="item.status === '0'">
                <template v-slot:activator="{on, attrs}">
                  <v-btn v-bind="attrs" v-on="on" @click="displayDisapprove(item, 'disapprove', true)" color="primary" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Disapprove</span>
              </v-tooltip>
            </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
      v-model="disapproveDialog"
      width="450"
      persistent
      >
      <v-card>
        <v-card-text class="text-h5 pa-12 text-center font-weight-bold" style="color: #1A2791">
           Do you want to reject this transaction?
          </v-card-text>
        <v-card-actions>
          <v-btn
            class="ml-6 mb-5"
            @click="closeDialog('disapprove')"
            color="primary"
            elevation="1"
            text
            outlined
            large
            style="width: 150px;"
          >
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm mr-6 mb-5"
            elevation="1"
            @click="processApproval(selectedItem, selectedAction)"
            large
            style="width: 150px;"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
      <v-dialog
      v-model="approveDialog"
      width="450"
      persistent
      >
      <v-card>
          <v-card-text class="text-h5 pa-12 text-center font-weight-bold" style="color: #1A2791">
           The transaction has been approved.
          </v-card-text>

          <div class="d-flex justify-center pa-4">
          <v-btn
              style="width: 200px;"
              color="primary"
              class="ph-action-button ph-action-button-receive-confirm mb-5"
              elevation="0"
              @click="closeDialog('approve')"
              large
            >
              Done
            </v-btn>
        </div>
      </v-card>
      </v-dialog>
      <v-dialog
      v-model="viewDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          {{ convertToUpperCase(itemDetails.service_code) }} Approval/Disapproval Details
        </v-card-title>
        <br>
        <v-card-text>
          <v-row>
            <v-col :cols="4">
              Status:
            </v-col>
            <v-col :cols="8">
              <strong v-if="itemDetails.status === '2'"> Approved </strong>
              <strong v-if="itemDetails.status === '3'"> Disapproved </strong>
            </v-col>
            <v-col :cols="4">
              Requested By:
            </v-col>
            <v-col :cols="8">
              <strong>{{ itemDetails.requesting_fla }}</strong>
            </v-col>
            <v-col :cols="4">
              Requested Date:
            </v-col>
            <v-col :cols="8">
              <strong>{{ formatDate(itemDetails.requested_date) }}</strong>
            </v-col>
            <v-col :cols="4">
              Type of Adjustment:
            </v-col>
            <v-col :cols="8">
              <strong>{{ itemDetails.action_taken }}</strong>
            </v-col>
            <v-col :cols="4">
              Reason of Adjustment
            </v-col>
            <v-col :cols="8">
              <strong>{{ itemDetails.remarks }}</strong>
            </v-col>
            <v-col :cols="4">
              Approved By:
            </v-col>
            <v-col :cols="8">
              <strong>{{ itemDetails.rh_name }}</strong>
            </v-col>
            <v-col :cols="4">
              Approved Date:
            </v-col>
            <v-col :cols="8">
              <strong>{{ itemDetails.approved_date }}</strong>
            </v-col>
            <v-col :cols="4">
              Approved Remarks:
            </v-col>
            <v-col :cols="8">
              <strong>{{ itemDetails.remarks }}</strong>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="closeViewDialog"
            class="ph-action-button"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-fab-transition>
        <v-btn
          color="primary"
          fab
          large
          fixed
          dark
          bottom
          right
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-container>
  </div>
</template>
<script>
import numeral from 'numeral'
import { renderToast } from '@/utils'
import { mapGetters } from 'vuex'
import { format } from 'date-fns'

export default {
  name: 'TransactionEditApproval',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Transaction Edit Approval',
          disabled: true,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Location Name',
          sortable: true,
          value: 'location_name'
        },
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'trx_date'
        },
        {
          text: 'Service Code',
          sortable: true,
          value: 'service_code'
        },
        {
          text: 'Request Type',
          sortable: true,
          value: 'action_taken'
        },
        {
          text: 'Amount',
          sortable: true,
          value: 'orig_amount'
        },
        {
          text: 'Requested By',
          sortable: true,
          value: 'requesting_fla'
        },
        {
          text: 'Requested Date',
          sortable: true,
          value: 'requested_date'
        },
        {
          text: 'Approved By',
          sortable: true,
          value: 'rh_name'
        },
        {
          text: 'Approved Date',
          sortable: true,
          value: 'approved_date'
        },
        {
          text: 'Action',
          sortable: true,
          value: 'action',
          align: 'center',
          width: '110px'
        }
      ],
      // gridListFiltered: [],
      selectedAction: '',
      selectedItem: [],
      disapproveDialog: false,
      approveDialog: false,
      searchText: '',
      viewDialog: false,
      itemDetails: []
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      gridList: 'transactionEditApproval/gridList',
      collections: 'collections'
    })
  },
  async beforeMount () {
    await this.getApprovalList()
  },
  methods: {
    renderToast,
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    formatDate (date) {
      if (date) return format(new Date(date), 'yyyy-MM-dd')
      return ''
    },
    // filter lists of transaction edit for approval
    async getApprovalList () {
      await this.$store.dispatch('transactionEditApproval/getGrid', this.currUser.user_id)
      // this.gridListFiltered = this.gridList.filter(res => res.status === '1')
    },
    // show disapprove dialog
    displayDisapprove (item, action, condition) {
      this.selectedAction = action
      this.selectedItem = item
      this.disapproveDialog = condition
    },
    // execute action
    async processApproval (item, action, condition) {
      const payload = {
        module: item.service_code,
        location_id: item.location_id,
        user_id: this.currUser.user_id,
        id: item.id,
        trx_id: item.trx_id,
        reason: item.remarks,
        client_ip: this.currUser.ip_address,
        action: action === 'approve' ? 2 : 3,
        requestor_id: this.currUser.user_id
      }
      if (action === 'disapprove') {
        const res = await this.$store.dispatch('transactionEditApproval/trxAction', payload)
        if (res.code >= 200 && res.code <= 299) {
          this.disapproveDialog = false
          renderToast('success', 'Transaction Rejected', 'The transaction has been rejected')
          await this.getApprovalList()
        } else {
          renderToast('error', 'Error', res.message ? res.message : 'An error has occured')
        }
      } else {
        const res = await this.$store.dispatch('transactionEditApproval/trxAction', payload)
        if (res.code >= 200 && res.code <= 299) {
          await this.getApprovalList()
          this.approveDialog = condition
        } else {
          renderToast('error', 'Error', res.message ? res.message : 'An error has occured')
        }
      }
    },
    closeDialog (action) {
      action === 'disapprove' ? this.disapproveDialog = false : this.approveDialog = false
      this.selectedAction = ''
      this.selectedItem = []
    },
    viewDetails (item) {
      this.viewDialog = true
      this.itemDetails = item
    },
    closeViewDialog () {
      this.viewDialog = false
      this.itemDetails = []
    },
    convertToUpperCase (item) {
      if (item) {
        return item.toUpperCase()
      }
    }
  }
}
</script>
